import React, { useEffect, useState } from 'react'
import { X, Edit, Trash2 } from 'lucide-react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'

const Start = () => {
  const [menuStatus, setMenuStatus] = useState(false)

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]"></div>

          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Start
