import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import { Save, Clock, Trash2 } from 'lucide-react'
import authorizePut from '../api/authorizedPut'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import { useAppContext } from '../DataContex'

const EditDepartment = () => {
  const navigate = useNavigate()
  const [content, setContent] = useState(false)
  const { depData } = useAppContext()
  const [menuStatus, setMenuStatus] = useState(false)
  const [id, setID] = useState('')
  const [value, setValue] = useState('')
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [Image, setImage] = useState('')
  const [thumbnail, setThumbnail] = useState('')

  const ID = localStorage.getItem('ID');

  useEffect(() => {
    if (depData) {
      setID(depData?.id)
      setValue(depData?.content)
      setTitle(depData?.title)
      setName(depData?.name)
      setImage(depData?.imageUrl)
      setThumbnail(`${process.env.REACT_APP_API_URL}` + depData.imageUrl)
      setContent(true)
    }else(
      navigate('/country_department')
    )
  }, [depData, navigate])

  const handleFileChange = async (event) => {
    const file = event.target.files?.[0]
    if (file) {
      setImage(file)
      const img = await convertImage(file)
      setThumbnail(img)
    }
  }

  const convertImage = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = () => {
        reject(fileReader.error)
      }
    })
  }

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ]

  const modules = {
    toolbar: toolbarOptions,
  }

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  const handleEditDep = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('name', name)
    formData.append('title', title)
    formData.append('content', value)
    formData.append('image', Image)

    try {
      const response = await authorizePut(`department/${ID}/departments/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      console.log('Department post edited successfully', response)
      navigate('/country_department')
    } catch (error) {
      console.log('Error editing department post', error)
      toast.error('Error editing department post')
    }
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        {content && (
          <div className="main-panel">
            <div className="content2">
              <div>
                <div className="md:flex md:gap-4">
                  <section className="md:w-8/12 bg-white">
                    <div className="p-4">
                      <h4 className="card-title">EDIT DEPARTMENT POST</h4>
                      <form onSubmit={handleEditDep}>
                      <div className='flex gap-x-4'>
                    <div className="w-full border-b-2 border-gray-500 mb-4">
                        <input
                          type="text"
                          placeholder="Department Name"
                          className="w-full"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="w-full border-b-2 border-gray-500 mb-4">
                        <input
                          type="text"
                          placeholder="Title"
                          className="w-full"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </div>
                      </div>
                        <div className="h-[65vh] md:h-[55vh]">
                          <ReactQuill
                            modules={modules}
                            theme="snow"
                            value={value}
                            onChange={setValue}
                            className="h-[40vh]"
                          />
                        </div>
                        <div className="my-4">
                          <h4 className="card-title">Add Thumbnail</h4>
                          {thumbnail && (
                            <img
                              src={thumbnail}
                              className="w-40"
                              alt="Thumbnail"
                            />
                          )}
                          <input
                            type="file"
                            accept=".jpeg, .png, .jpg"
                            onChange={handleFileChange}
                          />
                        </div>
                        <button
                          type="submit"
                          className="py-2 px-8 font-bold  shadow-md rounded-lg text-white  bg-[#ef6603] w-full md:w-36"
                        >
                          Publish
                        </button>
                      </form>
                    </div>
                  </section>
                  <div className='flex w-full md:items-end md:w-4/12'>
                  <section className="w-full h-[30vh] bg-white ">
                  <div className="">
                    <h4 className="card-title w-full bg-[#d8c0dd] text-white p-3">
                      More options
                    </h4>
                    <div className="px-2 ">
                      <div className="w-full grid">
                        <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg py-2 px-6 my-1 gap-x-2">
                          <Save size={20} />
                          Save Draft
                        </button>
                        <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg  py-2 px-6 my-1 gap-x-2">
                          <Clock size={20} />
                          Schedule
                        </button>
                        <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg  py-2 px-6 my-1 gap-x-2">
                          <Trash2 size={20} />
                          Discard
                        </button>
                      </div>
                    </div>
                  </div>
                  </section>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </div>
      <Toaster />
    </div>
  )
}

export default EditDepartment
