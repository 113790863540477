import React, { useEffect, useState, useRef } from 'react'
import { X, Edit, Trash2, Image } from 'lucide-react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetRequest from '../api/authorizeGetRequest'
import authorizeDeleteRequest from '../api/authorizeDeleteRequest'
import authorizePost from '../api/authorizePost'
import authorizePut from '../api/authorizedPut'
import toast, { Toaster } from 'react-hot-toast'

const Room = () => {
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [create, setCreate] = useState(false)
  const [newMeeting, setNewMeeting] = useState('')
  const [edit, setEdit] = useState(false)
  const [currentMeeting, setCurrentMeeting] = useState('')
  const [conf, setConf] = useState(true)
  const [del, setDel] = useState('')
  const [newDate, setNewDate] = useState('')
  const [from, setFrom] = useState('00:00')
  const [to, setTo] = useState('00:00')
  const fileInputRef = useRef(null)
  const [image, setImage] = useState('')
  const [view, setView] = useState(null)

  const createRoom = (e) => {
    e.preventDefault()
    setCreate(true)
  }

  const closeCreate = (e) => {
    e.preventDefault()
    setCreate(false)
    setNewMeeting('')
    setNewDate('')
    setFrom('00:00')
    setTo('00:00')
    setView(null)
    setImage('')
  }

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

    const getData = async () => {
      try {
        const response = await authorizeGetRequest('meeting')
        setData(response)
        setLoading(false)
      } catch (error) {
        console.log('Error fetching meetings', error)
        toast.error('Error fetching meetings')
        setLoading(false)
      }
    }

    useEffect(() => {
      getData()
    }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('image', image)
    formData.append('title', newMeeting)
    formData.append('startTime', new Date(`${newDate}T${from}`).toISOString())
    formData.append('endTime', new Date(`${newDate}T${to}`).toISOString())

    try {
      await authorizePost('meeting', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      getData()

      setCreate(false)
      setNewMeeting('')
      setNewDate('')
      setFrom('00:00')
      setTo('00:00')
      setView(null)
      setImage('')
    } catch (error) {
      console.log('Error creating meeting', error)
    }
  }

  const handleEdit = (meeting) => {
    setView(`${process.env.REACT_APP_API_URL}` + meeting.image)
    setImage(meeting.image)
    setCurrentMeeting(meeting)
    setNewDate(meeting.startTime.split('T')[0])
    setFrom(meeting.startTime.split('T')[1].substring(0, 5))
    setTo(meeting.endTime.split('T')[1].substring(0, 5))
    setEdit(true)
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('image', image)
    formData.append('title', currentMeeting.title)
    formData.append('startTime', new Date(`${newDate}T${from}`).toISOString())
    formData.append('endTime', new Date(`${newDate}T${to}`).toISOString())

    try {
      await authorizePut(`meeting/${currentMeeting.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      getData()

      setEdit(false)
      setNewMeeting('')
      setNewDate('')
      setFrom('00:00')
      setTo('00:00')
      setView(null)
      setImage('')
    } catch (error) {
      console.log('Error updating meeting', error)
      toast.error('Error updating meeting')
    }
  }

  const confirmDelete = (id) => {
    setConf(true)
    setDel(id)
  }

  const handleDelete = async (id) => {
    try {
      await authorizeDeleteRequest(`meeting/${id}`)
      setData(data.filter((item) => item.id !== id))
    } catch (error) {
      console.log('Error deleting meeting', error)
    }
  }

  const handleClose = () => {
    setConf(false)
    setView(null)
    setImage('')
  }

  const handleClose2 = () => {
    setEdit(false)
    setNewMeeting('')
    setNewDate('')
    setFrom('00:00')
    setTo('00:00')
    setView(null)
    setImage('')
  }

  const handleImageAdd = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const addImage = async (viewImage) => {
    const file = viewImage.target.files?.[0]
    if (file) {
      setView(URL.createObjectURL(file))
      setImage(file)
    }
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          {conf && (
            <>
              <section>
                {data.map((item) => (
                  <div key={item.id}>
                    {item.id === del && (
                      <section className="w-full flex justify-center">
                        <div className="fixed w-80 my-60 z-[999] bg-white  shadow-lg p-1 rounded-lg ">
                          <div className=" w-full flex justify-end">
                            <X onClick={handleClose} size={30} className="" />
                          </div>
                          <div className="text-center w-full px-4 pb-2">
                            <h2 className="">
                              Confirm to delete{' '}
                              <span className="text-red-500 font-bolder">
                                "{item.title}"
                              </span>
                            </h2>
                            <p className="italic text-sm text-red-500">
                              <span className="font-bold">Warning:</span> data
                              will not be recoverable after deletion!
                            </p>
                            <div className="w-full flex justify-center gap-x-4 my-2">
                              <div
                                onClick={handleClose}
                                className="p-2 px-4  text-gray-300 shadow-md font-bold rounded-lg cursor-pointer"
                              >
                                Cancel
                              </div>
                              <div
                                onClick={() => handleDelete(del)}
                                className="p-2 px-4 bg-[#ef6603] hover:bg-[#ef6603] text-white font-bold rounded-md cursor-pointer"
                              >
                                Confirm
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                ))}
              </section>
            </>
          )}

          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]">
            {loading ? (
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className=" px-2 py-4">
                      <div className="flex items-center justify-between">
                        <h4 className="card-title px-3">Rooms</h4>
                        <button
                          onClick={createRoom}
                          className=" rounded-lg shadow-md py-2  bg-[#ef6603] text-white px-2 md:px-4 mx-4"
                        >
                          + Create Room
                        </button>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> Name </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              Array.isArray(data) &&
                              data.map((item, index) => (
                                <tr className="flex justify-between border-b">
                                  <div key={index}>
                                    <td className="semi-bold text-xl">
                                      {item.title}
                                    </td>
                                  </div>
                                  <div className="hidden md:flex  ">
                                    <td>
                                      <button
                                        type="button"
                                        className=" text-white bg-[#ef6603] shadow-md rounded-lg flex items-last justify-last py-2 px-3 md:px-4"
                                        onClick={() => handleEdit(item)}
                                      >
                                        <Edit className="mr-2" size={15} /> Edit
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className=" text-white bg-[#ef6603]  shadow-md rounded-lg flex items-last justify-last py-2 px-3 md:px-4 hover:border-red-700"
                                        onClick={() => confirmDelete(item.id)}
                                      >
                                        <Trash2
                                          className="mr-2 text-white"
                                          size={15}
                                        />{' '}
                                        Delete
                                      </button>
                                    </td>
                                  </div>
                                  <div className="flex  md:hidden ">
                                    <td>
                                      <Edit
                                        onClick={() => handleEdit(item)}
                                        className="mr-2 text-[#ef6603] "
                                        size={15}
                                      />
                                    </td>
                                    <td>
                                      <Trash2
                                        onClick={() => confirmDelete(item.id)}
                                        className="mr-2 text-[#ef6603]"
                                        size={15}
                                      />
                                    </td>
                                  </div>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5}>No Room created yet.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {create && (
            <section className="fixed w-full md:w-[83%] h-[90vh] bg-black bg-opacity-10 backdrop-blur-sm">
              <div className="w-full flex justify-end">
                <X className="cursor-pointer mr-5 mt-2" onClick={closeCreate} />
              </div>
              <div className=" w-[90%] md:w-[30%] md:w-50 rounded-lg bg-white p-4 flex justify-center m-auto border border-black">
                <div className="w-full">
                  <h4 className="text-center text-lg">Create New Meeting</h4>
                  <form className="w-full" onSubmit={handleSubmit}>
                    <div>
                      <div
                        className={`w-full flex items-center justify-center border-2 border-gray-300 rounded-lg mt-2 cursor-pointer ${
                          view ? 'p-0' : 'p-4'
                        }`}
                        onClick={handleImageAdd}
                      >
                        {view ? (
                          <img src={view} alt="thumbnail" className="w-40" />
                        ) : (
                          <Image size={100} className="text-gray-300" />
                        )}
                      </div>
                      <input
                        ref={fileInputRef}
                        type="file"
                        name="image"
                        style={{ display: 'none' }}
                        accept=".jpeg,.png,.jpg"
                        onChange={addImage}
                        required
                      />
                    </div>
                    <div className="w-full my-4 border-b border-black">
                      <label htmlFor="name">Title:</label>
                      <input
                        type="text"
                        className="w-full"
                        value={newMeeting}
                        onChange={(e) => setNewMeeting(e.target.value)}
                        required
                      />
                    </div>
                    <div className="w-full my-4 border-b border-black">
                      <label htmlFor="name">Date:</label>
                      <input
                        type="date"
                        className="w-full"
                        value={newDate}
                        onChange={(e) => setNewDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="w-full my-4 flex items-center ">
                      <label htmlFor="duration">Duration:</label>
                      <div className="flex items-center md:text-lg mx-2 font-bold">
                        <input
                          type="time"
                          name="timeField"
                          value={from}
                          onChange={(e) => setFrom(e.target.value)}
                          required
                        />
                        <p className="mx-2 md:mx-3">-</p>
                        <input
                          type="time"
                          value={to}
                          onChange={(e) => setTo(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="w-full bg-[#ef6603] font-bold text-white hover:bg-[#ef6603] p-2"
                    >
                      Create
                    </button>
                  </form>
                </div>
              </div>
            </section>
          )}
          {edit && (
            <section className="fixed w-full md:w-[83%] h-[90vh] bg-black bg-opacity-10 backdrop-blur-sm">
              <div className="w-full flex justify-end">
                <X
                  className="cursor-pointer mr-5 mt-2"
                  onClick={handleClose2}
                />
              </div>
              <div className="w-[90%] md:w-[30%] md:w-50 rounded-lg bg-white p-4 flex justify-center m-auto border border-black">
                <div className="w-full">
                  <h4 className="text-center text-lg">Edit Meeting</h4>
                  <form className="w-full" onSubmit={handleEditSubmit}>
                    <div>
                      <div
                        className={`w-full h-40 flex items-center justify-center border-2 border-gray-300 rounded-lg mt-2 cursor-pointer overflow-hidden ${
                          view ? 'p-0' : 'p-4'
                        }`}
                        onClick={handleImageAdd}
                      >
                        {view ? (
                          <img src={view} alt="thumbnail" className="w-full " />
                        ) : (
                          <Image size={100} className="text-gray-300" />
                        )}
                      </div>
                      <input
                        ref={fileInputRef}
                        type="file"
                        placeholder="Thumbnail"
                        style={{display: 'none'}}
                        accept=".jpeg,.png,.jpg"
                        onChange={addImage}
                        required
                      />
                    </div>
                    <div className="w-full my-4 border-b border-black">
                      <label htmlFor="name">Title:</label>
                      <input
                        type="text"
                        className="w-full"
                        value={currentMeeting.title}
                        onChange={(e) =>
                          setCurrentMeeting({
                            ...currentMeeting,
                            title: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="w-full my-4 border-b border-black">
                      <label htmlFor="name">Date:</label>
                      <input
                        type="date"
                        className="w-full"
                        value={newDate}
                        onChange={(e) => setNewDate(e.target.value)}
                      />
                    </div>
                    <div className="w-full my-4 flex items-center ">
                      <label htmlFor="duration">Duration:</label>
                      <div className="flex items-center md:text-lg mx-2 font-bold">
                        <input
                          type="time"
                          name="timeField"
                          value={from}
                          onChange={(e) => setFrom(e.target.value)}
                        />
                        <p className="mx-2 md:mx-3">-</p>
                        <input
                          type="time"
                          value={to}
                          onChange={(e) => setTo(e.target.value)}
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="w-full bg-[#ef6603] font-bold text-white hover:bg-[#ef6603] p-2"
                    >
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </section>
          )}
          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default Room
