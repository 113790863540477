import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetRequest from '../api/authorizeGetRequest'
import authorizeDeleteRequest from '../api/authorizeDeleteRequest'
import { Edit, Trash2, X } from 'lucide-react'
import toast, { Toaster } from 'react-hot-toast'
import { useAppContext } from '../DataContex'
import authorizePut from '../api/authorizedPut'
import { useNavigate } from 'react-router-dom'

const JobApplicants = () => {
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [country, setCountry] = useState('')
  const [conf, setConf] = useState(true)
  const [del, setDel] = useState('')

  const { user, setJobData } = useAppContext()
  const navigate = useNavigate()

  const sortData = (data, key) => {
    return [...data].sort((a, b) => {
      const dateA = new Date(a[key])
      const dateB = new Date(b[key])
      return dateB - dateA 
    })
  }

  const getData = async () => {
    try {
      const response = await authorizeGetRequest(`job`)
      console.log('jobSeekers', response)
      setData(sortData(response, 'createdAt'))
      setLoading(false)
    } catch (error) {
      console.log('error logging in', error)
      setLoading(false)
    }
  }

    const getCountry = async () => {
      try {
        const response = await authorizeGetRequest('countries')
        setCountry(response)
      } catch (error) {
        console.log('Error fetching countries', error)
        toast.error('Error fetching countries')
      }
    }
    

  useEffect(() => {
    getData()
    getCountry()
  }, [])
  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  const confirmDelete = (id) => {
    setConf(true)
    setDel(id)
  }

  const handleDelete = async (id) => {
    try {
      await authorizeDeleteRequest(`job/${id}`)
      setData(data.filter((item) => item.id !== id))
    } catch (error) {
      console.log('Error removing Job from the list: ', error)
      toast.error('Error removing Job from the list')
    }
  }

  const handleClose = () => {
    setConf(false)
  }

  const statusCheck = async (id) => {
    const jobStatus = data.find((item) => item.id === id)

    try {
      await authorizePut(`job/${id}`, {
        status: !jobStatus.status,
      })
      toast.success('Job status updated successfully')
      getData()
    } catch (error) {
      console.log('Error changing job status: ', error)
      toast.error('Error changing job status')
    }
  }

  const handleEdit = (id) => {
    navigate('/edit_job')
    setJobData(id)
  }

  return (
    <div class="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div class="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div class="main-panel">
          {conf && (
            <>
              <section>
                {data.map((item) => (
                  <div key={item.id}>
                    {item.id === del && (
                      <section className="w-full flex justify-center">
                        <div className="fixed w-80 my-60 z-[999] bg-white shadow-lg p-1 rounded-md ">
                          <div className=" w-full flex justify-end">
                            <X onClick={handleClose} size={30} className="" />
                          </div>
                          <div className="text-center w-full px-4 pb-2">
                            <h2 className="">
                              Confirm to delete{' '}
                              <span className="text-red-500 font-bolder">
                                "{item.title}"
                              </span>
                            </h2>
                            <p className="italic text-sm text-red-500">
                              <span className="font-bold">Warning:</span> data
                              will not be recoverable after deletion!
                            </p>
                            <div className="w-full flex justify-center gap-x-4 my-2">
                              <div
                                onClick={handleClose}
                                className="p-2 px-4  text-gray-300 shadow-lg  font-bold rounded-md cursor-pointer"
                              >
                                Cancel
                              </div>
                              <div
                                onClick={() => handleDelete(del)}
                                className="p-2 px-4 bg-[#ef6603] hover:bg-[#ef6603] text-white font-bold rounded-md cursor-pointer"
                              >
                                Confirm
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                ))}
              </section>
            </>
          )}
          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]">
            {loading ? (
              <div class="loader m-5 text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col-12 grid-margin">
                  <div class="card">
                    <div className=" px-2 py-4">
                      <div className="flex items-center justify-between">
                        <h4 className="md:text-lg text-sm px-3 ">
                          Open Job Listing
                        </h4>
                        <div>
                          {(user.role === 'admin' ||
                            user.role === 'countryAdmin') && (
                            <a
                              href="/create_job"
                              className=" shadow-lg rounded-md bg-[#ef6603] text-sm md:text-md text-white py-2 px-4 mx-4"
                            >
                              + Create Job
                            </a>
                          )}
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr className="text-center">
                              <th className="text-start"> Title </th>
                              <th> Country </th>
                              <th> Duration</th>
                              <th> Status</th>
                              <th>Application</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length >= 1 ? (
                              Array.isArray(data) &&
                              data.map((item, index) => (
                                <tr key={index} className="text-center">
                                  <td className="text-start">{item.title}</td>
                                  <td>{Array.isArray(country) && country.map(coun =>(
                                     coun.id === item.location &&
                                     <span>{coun.name}</span>
                                  ))}</td>
                                  <td>
                                    {item.createdAt.split('T')[0]} -{' '}
                                    {item.deadline
                                      ? item.deadline.split('T')[0]
                                      : 'No deadline'}
                                  </td>
                                  {item.isMain ?(
                                    <p className='font-bold text-center'>Main</p>
                                  ):(
                                    <td>
                                    <label class="switch">
                                    <input
                                        type="checkbox"
                                        checked={item.status}
                                        onClick={() => statusCheck(item.id)}
                                      />
                                      <span class="slider round"></span>
                                      </label>
                                      </td>
                                    )}
                                  <td>0</td>
                                  <td className="hidden md:flex gap-x-4 justify-end">
                                    <button
                                      type="button"
                                      className="bg-[#ef6603] text-white rounded-lg shadow-md  flex items-center justify-center py-2 px-3 md:px-4"
                                      onClick={() => handleEdit(item)}
                                    >
                                      <Edit className="mr-2" size={15} /> Edit
                                    </button>
                                    <button
                                      type="button"
                                      className="bg-[#ef6603] text-white rounded-lg shadow-md flex items-center justify-center py-2 px-3 md:px-4 h"
                                      onClick={() => confirmDelete(item.id)}
                                    >
                                      <Trash2
                                        className="mr-2 text-white"
                                        size={15}
                                      />{' '}
                                      Delete
                                    </button>
                                  </td>
                                  <td className="flex md:hidden gap-x-4">
                                    <Edit
                                      onClick={() => handleEdit(item)}
                                      className="mr-2 text-[#ef6603] "
                                      size={15}
                                    />
                                    <Trash2
                                      onClick={() => confirmDelete(item.id)}
                                      className="mr-2 text-[#ef6603]"
                                      size={15}
                                    />
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5}>No Job Available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Toaster />
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default JobApplicants
