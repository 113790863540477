import React, { useState, useEffect, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import { Save, Clock, Trash2 } from 'lucide-react'
import authorizeGetRequest from '../api/authorizeGetRequest'
import authorizeCreate from '../api/authorizeCreate'
import authorizePost from '../api/authorizePost'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'

const CreateBlog = () => {
  const navigate = useNavigate()
  const [menuStatus, setMenuStatus] = useState(false)
  const [value, setValue] = useState('')
  const [data, setData] = useState([])
  const [newCategory, setNewCategory] = useState('')
  const [title, setTitle] = useState('')
  const [category, setCategory] = useState('')
  const [publication, setPublication] = useState('')
  const [blogImage, setBlogImage] = useState('')
  const fileInputRef = useRef(null)
  const [thumbnail, setImg] = useState('')

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await authorizeGetRequest('categories')
        setData(response.data)
      } catch (error) {
        console.log('Error fetching categories', error)
        toast.error('Error fetching categories')
      }
    }
    getData()
  }, [])

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ]

  const modules = {
    toolbar: toolbarOptions,
  }

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  const handleCreateBlog = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('title', title)
    formData.append('content', value)
    formData.append('categoryIds', parseFloat(category))
    if (publication === 'published') {
      formData.append('published', true)
      formData.append('isFeatured', false)
    } else if (publication === 'featured') {
      formData.append('published', false)
      formData.append('isFeatured', true)
    } else {
      console.log('Select Publication type')
      toast.error('Publication type cannot be empty')
      return
    }

    if (blogImage) {
      formData.append('image', blogImage)
    }

    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value)
    // }

    try {
      const response = await authorizePost('posts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      console.log('Blog created successfully', response)
      navigate('/Blog')
    } catch (error) {
      console.log('Error creating blog', error)
      toast.error('Error creating blog')
    }
  }

  const handleCreateCategory = async (e) => {
    e.preventDefault()
    try {
      const newCategoryData = await authorizeCreate('categories', {
        name: newCategory,
      })
      setData([...data, newCategoryData])
      setNewCategory('')

      const getData = async () => {
        try {
          const response = await authorizeGetRequest('categories')
          setData(response.data)
        } catch (error) {
          console.log('Error fetching categories', error)
          toast.error('Error fetching categories')
        }
      }
      getData()
    } catch (error) {
      console.log('Error creating category', error)
      toast.error('Error creating category')
    }
  }

  const convertImage = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = () => {
        reject(fileReader.error)
      }
    })
  }

  const handleFileChange = async (event) => {
    const file = event.target.files?.[0]
    if (file) {
      const img = await convertImage(file)
      setBlogImage(file)
      setImg(img)
    }
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          <div className="content2">
            <div>
              <div className="md:flex md:gap-4">
                <section className="md:w-8/12 bg-white">
                  <div className="p-4">
                    <h4 className="card-title">Create new blog</h4>
                    <form onSubmit={handleCreateBlog}>
                      <div className="w-full border-b-2 border-gray-500 mb-4">
                        <input
                          type="text"
                          placeholder="Title"
                          className="w-full"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="h-[65vh] md:h-[55vh]">
                        <ReactQuill
                          modules={modules}
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          className="h-[40vh]"
                        />
                      </div>
                      <div className="w-full flex items-center justify-between gap-x-4">
                        <div className="w-6/12 border-b-2 border-gray-500 mb-4 ">
                          <select
                            className="w-full"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value="">Select Category</option>
                            {data.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div>
                          <div className="w-full border-b-2 border-gray-500 mb-4">
                            <select
                              className="w-full"
                              value={publication}
                              onChange={(e) => setPublication(e.target.value)}
                            >
                              <option value="">Publication type</option>
                              <option value="published">Published</option>
                              <option value="featured">Featured</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="my-4">
                        <h4 className="card-title">Add Thumbnail</h4>
                        {thumbnail && (
                          <img
                            src={thumbnail}
                            className="w-40"
                            alt="Thumbnail"
                          />
                        )}
                        <input
                          ref={fileInputRef}
                          type="file"
                          accept=".jpeg, .png, .jpg"
                          onChange={handleFileChange}
                        />
                      </div>
                      <button
                        type="submit"
                        className="py-2 px-8 font-bold  shadow-md rounded-lg text-white  bg-[#ef6603] w-full md:w-36"
                      >
                        Post Blog
                      </button>
                    </form>
                  </div>
                </section>
                <section className="md:w-4/12 h-[70vh] bg-white rounded-lg">
                  <form className="w-full" onSubmit={handleCreateCategory}>
                    <div className="mb-4">
                      <h4 className="card-title w-full bg-[#d8c0dd] text-white p-3">
                        Add a new category
                      </h4>
                      <div className="border-1 border-gray-300 px-2 py-4">
                        <div className="flex items-center justify-center gap-x-2">
                          <i className="mdi mdi-view-list menu-icon"></i>
                          <div className="w-full border-b-2 border-gray-500">
                            <input
                              type="text"
                              placeholder="New Category"
                              className="w-full"
                              value={newCategory}
                              onChange={(e) => setNewCategory(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="mt-4 w-full flex justify-end">
                          <button
                            type="submit"
                            className="bg-[#ef6603] text-white shadow-md rounded-lg py-2 px-4"
                          >
                            + Add Category
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="">
                    <h4 className="card-title w-full bg-[#d8c0dd] text-white p-3">
                      More options
                    </h4>
                    <div className="px-2 ">
                      <div className="w-full grid">
                        <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg py-2 px-6 my-1 gap-x-2">
                          <Save size={20} />
                          Save Draft
                        </button>
                        <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg  py-2 px-6 my-1 gap-x-2">
                          <Clock size={20} />
                          Schedule
                        </button>
                        <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg  py-2 px-6 my-1 gap-x-2">
                          <Trash2 size={20} />
                          Discard
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default CreateBlog
