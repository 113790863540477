import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetPost from '../api/authorizeGetPost'
import authorizeDeleteRequest from '../api/authorizeDeleteRequest'
import {
  BookDashed,
  BookOpenCheck,
  Edit,
  Image,
  PenIcon,
  Phone,
  Trash2,
  Users,
  X,
} from 'lucide-react'
import toast, { Toaster } from 'react-hot-toast'
import { useAppContext } from '../DataContex'
import authorizePut from '../api/authorizedPut'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const CountryDepartment = () => {
  const navigate = useNavigate()
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState('')
  const [admin, setAdmin] = useState([])
  const [country, setCountry] = useState('')
  const [image, setImage] = useState('')
  const [view, setView] = useState('')
  const [authCountry, setAuthCountry] = useState([])
  const [data, setData] = useState([])
  const [conf, setConf] = useState(true)
  const [del, setDel] = useState('')
  const Reference = useRef(null)
  const [edit, setEdit] = useState(false)
  const [description, setDescription] = useState('')

  const ID = localStorage.getItem('ID')

  const { setDepData, user } = useAppContext()

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ]

  const modules = {
    toolbar: toolbarOptions,
  }

  const handleContact = (id)=>{
    localStorage.setItem('ContactID', id)
  }
  const handleTeam = (id) => {
    localStorage.setItem('TeamID', id)
  }

  const createDepartment = (e) => {
    e.preventDefault()
    navigate('/create_department')
  }

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  const fetchCountryData = async () => {
    try {
      const response = await authorizeGetPost(`countries/${ID}`)
      setDetails(response)
      setCountry(response.name)
      setAuthCountry(response.admins)
      setImage(response.image)
      setView(`${process.env.REACT_APP_API_URL}` + response.image)
      setDescription(response.description)
      console.log('Country: ', response)
    } catch (error) {
      console.log('Error fetching country', error)
      toast.error('Error fetching country')
    }
  }

  const getUser = async () => {
    try {
      const response = await authorizeGetPost('user/admins')
      setAdmin(response.admins)
      console.log('All Admins: ', response.admins)
    } catch (error) {
      console.log('Error fetching admins', error)
      toast.error('Error fetching admins')
    }
  }

  const getData = async () => {
    try {
      const response = await authorizeGetPost(`department/${ID}`)
      const dataArray = Object.values(response)
      setData(dataArray)
      console.log('response: ', dataArray)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching department:', error)
      toast.error('Error fetching department')
      setLoading(false)
    }
  }

  useEffect(() => {
    getUser()
    fetchCountryData()
    getData()
  }, [])

  const handleEdit = (id) => {
    navigate(`/edit_department`)
    setDepData(id)
  }

  const confirmDelete = (id) => {
    setConf(true)
    setDel(id)
  }

  const handleDelete = async (id) => {
    try {
      await authorizeDeleteRequest(`department/${ID}/departments/${id}`)
      setData(data.filter((item) => item.id !== id))
    } catch (error) {
      console.log('Error deleting department', error)
      toast.error('Error deleting department')
    }
  }

  const handleClose = () => {
    setConf(false)
  }

  const handlePublish = async (id) => {
    try {
      await authorizePut(`department/${ID}/department-publish/${id}`, {
        published: true,
      })

      getData()

      toast.success('Successfully published department')
    } catch (error) {
      console.log('Error publishing department', error)
      toast.error('Error publishing department')
    }
  }

  const handleUnpublish = async (id) => {
    try {
      await authorizePut(`department/${ID}/department-publish/${id}`, {
        published: false,
      })

      getData()

      toast.success('Successfully unpublished department')
    } catch (error) {
      console.log('Error unpublishing department', error)
      toast.error('Error unpublishing department')
    }
  }

  const handleAddImage = () => {
    if (Reference.current) {
      Reference.current.click()
    }
  }

  const handleImage = (CountryImage) => {
    const file = CountryImage.target.files[0]
    if (file) {
      setImage(file)
      const handleChangeImage = async () => {
        const formData = new FormData()
        formData.append('image', file)
        formData.append('name', details.name)
        try {
          await authorizePut(`countries/${ID}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          toast.success('Country image updated successfully')
          fetchCountryData()
        } catch (error) {
          console.log('Failed to upload image: ', error)
          toast.error('Failed to upload image')
        }
      }
      handleChangeImage()
    }
  }

  const handleSubmitDesc = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('description', description)
    formData.append('name', details.name)
    try {
      await authorizePut(`countries/${ID}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      toast.success('Country Description updated successfully')
      fetchCountryData()
      setEdit(false)
    } catch (error) {
      console.log('Error updating description: ', error)
      toast.error('Error updating description')
    }
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          {conf && (
            <>
              <section>
                {data.map((item) => (
                  <div key={item.id}>
                    {item.id === del && (
                      <section className="w-full flex justify-center">
                        <div className="fixed w-80 my-60 z-[999] bg-white shadow-lg p-1 rounded-md ">
                          <div className=" w-full flex justify-end">
                            <X onClick={handleClose} size={30} className="" />
                          </div>
                          <div className="text-center w-full px-4 pb-2">
                            <h2 className="">
                              Confirm to delete{' '}
                              <span className="text-red-500 font-bolder">
                                "{item.name}"
                              </span>
                            </h2>
                            <p className="italic text-sm text-red-500">
                              <span className="font-bold">Warning:</span> data
                              will not be recoverable after deletion!
                            </p>
                            <div className="w-full flex justify-center gap-x-4 my-2">
                              <div
                                onClick={handleClose}
                                className="p-2 px-4  text-gray-300 shadow-lg  font-bold rounded-md cursor-pointer"
                              >
                                Cancel
                              </div>
                              <div
                                onClick={() => handleDelete(del)}
                                className="p-2 px-4 bg-[#ef6603] hover:bg-[#ef6603] text-white font-bold rounded-md cursor-pointer"
                              >
                                Confirm
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                ))}
              </section>
            </>
          )}

          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]">
            {loading ? (
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className=" px-2 py-4">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="w-full uppercase flex items-center justify-between ">
                                {country}

                                <div className="flex items-center">
                                  <a
                                    href={'/team'}
                                    onClick={() => handleTeam(details.id)}
                                    className="flex items-center shadow-lg rounded-md border-2 border-[#ef6603] text-sm md:text-md text-[#ef6603] py-2 px-4 ml-2 gap-x-2 hover:border-[#ef6636] hover:bg-[#ef6636] hover:text-white normal-case"
                                  >
                                    <Users size={20} />
                                    Team
                                  </a>
                                  <a
                                    href={'/contact'}
                                    onClick={() => handleContact(details.id)}
                                    className="flex items-center shadow-lg rounded-md border-2 border-[#ef6603] text-sm md:text-md text-[#ef6603] py-2 px-4 ml-2 gap-x-2 hover:border-[#ef6636] hover:bg-[#ef6636] hover:text-white normal-case"
                                  >
                                    <Phone size={20} />
                                    Contact
                                  </a>
                                  {(user.role === 'admin' ||
                                    authCountry.includes(user.id)) && (
                                    <button
                                      className=" shadow-lg rounded-md border-2 border-[#ef6603] bg-[#ef6603] text-sm md:text-md text-white py-2 px-4 ml-2"
                                      onClick={createDepartment}
                                    >
                                      + Create department
                                    </button>
                                  )}
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <section>
                              {image ? (
                                authCountry.includes(user.id) ? (
                                  <div
                                    className="w-full flex justify-center"
                                    onClick={handleAddImage}
                                  >
                                    <img
                                      src={view}
                                      alt={details.name}
                                      className="h-[300px] w-full object-cover rounded-md shadow-lg"
                                    />
                                  </div>
                                ) : (
                                  <div className="w-full flex justify-center">
                                    <img
                                      src={view}
                                      alt={details.name}
                                      className="h-[300px] w-full object-cover rounded-md shadow-lg "
                                    />
                                  </div>
                                )
                              ) : user.role === 'admin' ||
                                authCountry.includes(user.id) ? (
                                <div
                                  className="w-full border-2 border-gray-300 rounded-md p-2 flex item-center justify-center cursor-pointer"
                                  onClick={handleAddImage}
                                >
                                  <Image size={300} className="text-gray-300" />
                                </div>
                              ) : (
                                <div className="w-full border-2 border-gray-300 rounded-md p-2 flex item-center justify-center">
                                  <Image size={300} className="text-gray-300" />
                                </div>
                              )}

                              <input
                                ref={Reference}
                                type="file"
                                style={{ display: 'none' }}
                                accept=".jpg, .png, .svg"
                                onChange={handleImage}
                              />

                              <div>
                                <h2 className="uppercase font-bold text-lg">
                                  Description:
                                </h2>
                                <div className="flex items-center">
                                  {details.description ? (
                                    <div
                                      className="text-md items-justify"
                                      dangerouslySetInnerHTML={{
                                        __html: details.description,
                                      }}
                                    />
                                  ) : (
                                    <p className="italic">
                                      -No description available-
                                    </p>
                                  )}
                                  {authCountry.includes(user.id) && (
                                    <PenIcon
                                      size={15}
                                      className="ml-2 cursor-pointer hover:text-blue-500"
                                      onClick={() => setEdit(true)}
                                    />
                                  )}
                                </div>
                              </div>
                            </section>
                            <h3 className="font-bold p-4 border-b uppercase">
                              Department List
                            </h3>
                            {data.length > 0 ? (
                              data.map((item, index) => (
                                <Fragment key={index}>
                                  <tr
                                    style={{ cursor: 'pointer' }}
                                    data-bs-target="#exampleModal"
                                    className="flex justify-between border-b"
                                  >
                                    <a
                                      href={`https://gataama.com/department/${ID}/departments/${item.id}/post`}
                                    >
                                      <td>{item.name}</td>
                                    </a>
                                    <td className="flex">
                                      <div className="w-full flex items-center justify-center italic opacity-70 mr-2 md:mr-40">
                                        {item.published ? (
                                          <p className="text-green-500">
                                            Published
                                          </p>
                                        ) : (
                                          <p className="text-red-500">
                                            pending...
                                          </p>
                                        )}
                                      </div>
                                      {(user.role === 'admin' ||
                                        authCountry.includes(user.id)) && (
                                        <>
                                          <div className="hidden md:flex">
                                            <td
                                              className={`${
                                                user.role !== 'admin' &&
                                                'hidden'
                                              }`}
                                            >
                                              {item.published === true ? (
                                                <button
                                                  type="button"
                                                  className={`bg-red-500 text-white rounded-lg shadow-md  flex items-center justify-center py-2 px-3 md:px-4`}
                                                  onClick={() =>
                                                    handleUnpublish(item.id)
                                                  }
                                                >
                                                  <BookDashed
                                                    className="mr-2"
                                                    size={15}
                                                  />{' '}
                                                  Unpublish
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className={`bg-[#ef6603] text-white rounded-lg shadow-md  flex items-center justify-center py-2 px-3 md:px-4`}
                                                  onClick={() =>
                                                    handlePublish(item.id)
                                                  }
                                                >
                                                  <BookOpenCheck
                                                    className="mr-2"
                                                    size={15}
                                                  />{' '}
                                                  Publish
                                                </button>
                                              )}
                                            </td>
                                            <td>
                                              <button
                                                type="button"
                                                className="bg-[#ef6603] text-white rounded-lg shadow-md  flex items-center justify-center py-2 px-3 md:px-4"
                                                onClick={() => handleEdit(item)}
                                              >
                                                <Edit
                                                  className="mr-2"
                                                  size={15}
                                                />{' '}
                                                Edit
                                              </button>
                                            </td>
                                            <td>
                                              <button
                                                type="button"
                                                className="bg-[#ef6603] text-white rounded-lg shadow-md flex items-center justify-center py-2 px-3 md:px-4 h"
                                                onClick={() =>
                                                  confirmDelete(item.id)
                                                }
                                              >
                                                <Trash2
                                                  className="mr-2 text-white"
                                                  size={15}
                                                />{' '}
                                                Delete
                                              </button>
                                            </td>
                                          </div>
                                          <div className="flex  md:hidden ">
                                            <td>
                                              <Edit
                                                onClick={() => handleEdit(item)}
                                                className="mr-2 text-[#ef6603] "
                                                size={15}
                                              />
                                            </td>
                                            <td>
                                              <Trash2
                                                onClick={() =>
                                                  confirmDelete(item.id)
                                                }
                                                className="mr-2 text-[#ef6603]"
                                                size={15}
                                              />
                                            </td>
                                          </div>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </Fragment>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5}>No department available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {edit && (
            <section className="fixed w-full md:w-[83%] h-[90vh] bg-black bg-opacity-10 backdrop-blur-sm">
              <div className="w-full md:flex justify-end hidden">
                <X
                  className="cursor-pointer mr-5 mt-2"
                  onClick={() => setEdit(false)}
                />
              </div>
              <div className="w-full md:w-[50%] h-full md:h-[75vh] rounded-lg bg-white p-4 flex justify-center m-auto border border-black">
                <div className="w-full">
                  <h4 className="text-center text-lg">Edit Description</h4>
                  <form className="my-4" onSubmit={handleSubmitDesc}>
                    <div className="h-[75vh] md:h-[55vh]">
                      <ReactQuill
                        modules={modules}
                        theme="snow"
                        value={description}
                        onChange={setDescription}
                        className="h-[57vh] md:h-[40vh]"
                      />
                    </div>
                    <div className="flex w-full items-center gap-x-4">
                      <button
                        className="rounded-md bg-red-500 px-4 py-2 text-white font-bold"
                        onClick={() => setEdit(false)}
                      >
                        Cancel
                      </button>
                      <button className="rounded-md bg-[#ef6603] px-4 py-2 text-white font-bold">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          )}
          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default CountryDepartment
