import axios from 'axios';

const authorizePost = async (url, data, config = {}) => {
  if (localStorage.token) {
    const headers = {
      Authorization: `Bearer ${localStorage.token}`,
      ...config.headers,
    };

    if (data instanceof FormData) {
      delete headers['Content-Type'];
    } else {
      headers['Content-Type'] = 'application/json';
    }

    const a = axios.create({ headers });

    let baseURL = `${process.env.REACT_APP_API_URL}/${url}`;
    return a
      .post(baseURL, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let res;
        if (error.response) {
          console.log('error1', error.response.data);
          throw error.response.data;
        } else if (error.request) {
          console.log('error2');
          res = { message: 'Network Error' };
          throw res;
        } else {
          console.log('error3');
          res = { message: 'Something went wrong, Refresh page' };
          throw res;
        }
      });
  } else {
    const res = { message: 'Login again to proceed' };
    throw res;
  }
};

export default authorizePost;
