import React, { createContext, useState, useContext, useEffect } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [token, setToken] = useState(() => localStorage.getItem('token') || null);

  useEffect(() => {
    if (user && token) {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    }
  }, [user, token]);

  const login = (userData) => {
    setUser(userData.user);
    setToken(userData.token);
  };

  const logout = () => {
    setUser(null);
    setToken(null);
  };

  // Data context logic
  const [blogData, setBlogData] = useState(null);
  const [depData, setDepData] = useState(null);
  const [jobData, setJobData] = useState(null);
  const [member, setMember] = useState(null);

  // Combine both user and data contexts
  const value = {
    user, token, login, logout,
    blogData, setBlogData,
    depData, setDepData,
    jobData, setJobData,
    member, setMember,
  };

  return (  
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};
