import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppContext  } from '../DataContex'; // Use the combined context

const ProtectedRoute = ({ element, allowedRoles }) => {
  const { user, token } = useAppContext (); // Access user and token from the combined context

  if (!token) {
    return <Navigate to="/login" />;
  }

  // If authenticated but not authorized, redirect to unauthorized page
  if (!allowedRoles.includes(user?.role)) {
    return <Navigate to="/unauthorized" />;
  }

  return element;
};

export default ProtectedRoute;
