import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetRequest from '../api/authorizeGetRequest'
import authorizeGetPost from '../api/authorizeGetPost'
import authorizeDeleteRequest from '../api/authorizeDeleteRequest'
import {
  BookDashed,
  BookOpenCheck,
  Building,
  Edit,
  Trash2,
  X,
} from 'lucide-react'
import toast, { Toaster } from 'react-hot-toast'
import { useAppContext } from '../DataContex'

const MyDepartment = () => {
  const navigate = useNavigate()
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [country, setCountry] = useState([])
  const [authCountry, setAuthCountry] = useState([])
  const [data, setData] = useState([])
  const [conf, setConf] = useState(true)
  const [del, setDel] = useState('')
  const [ID, setID] = useState('')

  const { setDepData, user } = useAppContext()

  const handleData = (id) => {
    localStorage.setItem('ID', id)
  }

  const createDepartment = (e) => {
    e.preventDefault()
    navigate('/create_department')
  }

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  const sortData = (data, key) => {
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    })
  }
  
  useEffect(() => {
    const getCountry = async () => {
      try {
        const response = await authorizeGetRequest('countries')
        setCountry(response)
        console.log('countries: ', response)
        handleUserCountryCheck(response)
      } catch (error) {
        toast.error('Failed to fetch country')
        console.log('Error fetching countries, ', error)
        setLoading(false)
      }
    }
    getCountry()
  }, [])

  const handleUserCountryCheck = (countries) => {
    const userCountries = countries.filter((country) =>
      country.admins.includes(user.id),
    )

    if (userCountries.length > 1) {
      setCountry(sortData(userCountries, 'name'))
      setLoading(false)
    } else if (userCountries.length === 1) {
      const singleCountry = userCountries[0]
      setCountry(singleCountry)
      setID(singleCountry.id)
      fetchDepartment(singleCountry.id)
    } else {
      setLoading(false)
    }
  }

  const fetchDepartment = async (countryId) => {
    try {
      const response = await authorizeGetPost(`department/${countryId}`)
      const dataArray = Object.values(response)
      setData(dataArray)
      console.log('Fetched department data: ', dataArray)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching department:', error)
      toast.error('Error fetching department')
      setLoading(false)
    }
  }

  const handleEdit = (id) => {
    navigate(`/edit_department`)
    setDepData(id)
  }

  const confirmDelete = (id) => {
    setConf(true)
    setDel(id)
  }

  const handleDelete = async (id) => {
    try {
      await authorizeDeleteRequest(`department/${ID}/departments/${id}`)
      setData(data.filter((item) => item.id !== id))
    } catch (error) {
      console.log('Error deleting department', error)
      toast.error('Error deleting department')
    }
  }

  const handleClose = () => {
    setConf(false)
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          {conf && (
            <>
              <section>
                {data.map((item) => (
                  <div key={item.id}>
                    {item.id === del && (
                      <section className="w-full flex justify-center">
                        <div className="fixed w-80 my-60 z-[999] bg-white shadow-lg p-1 rounded-md ">
                          <div className=" w-full flex justify-end">
                            <X onClick={handleClose} size={30} className="" />
                          </div>
                          <div className="text-center w-full px-4 pb-2">
                            <h2 className="">
                              Confirm to delete{' '}
                              <span className="text-red-500 font-bolder">
                                "{item.name}"
                              </span>
                            </h2>
                            <p className="italic text-sm text-red-500">
                              <span className="font-bold">Warning:</span> data
                              will not be recoverable after deletion!
                            </p>
                            <div className="w-full flex justify-center gap-x-4 my-2">
                              <div
                                onClick={handleClose}
                                className="p-2 px-4  text-gray-300 shadow-lg  font-bold rounded-md cursor-pointer"
                              >
                                Cancel
                              </div>
                              <div
                                onClick={() => handleDelete(del)}
                                className="p-2 px-4 bg-[#ef6603] hover:bg-[#ef6603] text-white font-bold rounded-md cursor-pointer"
                              >
                                Confirm
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                ))}
              </section>
            </>
          )}

          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]">
            {loading ? (
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="px-2 py-4">
                      <div className="flex items-center justify-between">
                        <h4 className="md:text-lg text-sm px-3">My Departments</h4>
                        <div>
                          {country > 1 && (
                          (user.role === 'admin' ||
                            authCountry.includes(user.id)) && (
                            <button
                              className=" shadow-lg rounded-md bg-[#ef6603] text-sm md:text-md text-white py-2 px-4 mx-4"
                              onClick={createDepartment}
                            >
                              + Create department
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table flex justify-between w-full">
                          <thead>
                            <tr>
                              <th className="">{country.name}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {country.length > 1 ? (
                              country.map((countryItem) => (
                                <tr key={countryItem.id}>
                                  <td className="semi-bold text-xl">
                                    <a
                                      href={`https://gataama.com/country/${countryItem.id}/departments`}
                                      className="grid md:flex"
                                    >
                                      {countryItem.name}
                                    </a>
                                  </td>
                                  <td className="hidden md:flex gap-x-4 items-center justify-end ">
                                    <a
                                      href={'country_department'}
                                      onClick={() => handleData(countryItem.id)}
                                      className="text-white bg-[#ef6603] shadow-md rounded-lg flex items-center justify-center py-2 px-3 md:px-4"
                                    >
                                      <div className="flex">
                                        <Building className="mr-2" size={15} />{' '}
                                        Departments
                                      </div>
                                    </a>
                                  </td>
                                  <td className="flex md:hidden gap-x-4 items-center ">
                                    <a href={'country_department'}>
                                      <Building
                                        onClick={() =>
                                          handleData(countryItem.id)
                                        }
                                        className="mr-2 text-[#ef6603]"
                                        size={15}
                                      />
                                    </a>
                                  </td>
                                </tr>
                              ))
                            ) : data.length > 0 ? (
                              data.map((department) => (
                                <tr key={department.id}>
                                  <td>
                                    <a
                                      href={`https://gataama-main.vercel.app/department/${ID}/departments/${department.id}/post`}
                                    >
                                      {department.name}
                                    </a>
                                  </td>
                                  <>
                                    <div className="hidden md:flex justify-end">
                                      <td className="flex">
                                        <div className="w-full flex items-center justify-center italic opacity-70 mr-60">
                                          {department.published === true ? (
                                            <p className="text-green-500">
                                              Published
                                            </p>
                                          ) : (
                                            <p className="text-red-500">
                                              pending...
                                            </p>
                                          )}
                                        </div>
                                        <button
                                          type="button"
                                          className="bg-[#ef6603] text-white rounded-lg shadow-md  flex items-center justify-center py-2 px-3 md:px-4"
                                          onClick={() => handleEdit(department)}
                                        >
                                          <Edit className="mr-2" size={15} />{' '}
                                          Edit
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="bg-[#ef6603] text-white rounded-lg shadow-md flex items-center justify-center py-2 px-3 md:px-4 h"
                                          onClick={() =>
                                            confirmDelete(department.id)
                                          }
                                        >
                                          <Trash2
                                            className="mr-2 text-white"
                                            size={15}
                                          />{' '}
                                          Delete
                                        </button>
                                      </td>
                                    </div>
                                    <div className="flex justify-end md:hidden ">
                                      <div className="w-full flex items-center justify-center italic opacity-70 mr-10">
                                        {department.published === true ? (
                                          <p className="text-green-500">
                                            Published
                                          </p>
                                        ) : (
                                          <p className="text-red-500">
                                            pending...
                                          </p>
                                        )}
                                      </div>
                                      <td>
                                        <Edit
                                          onClick={() => handleEdit(department)}
                                          className="mr-2 text-[#ef6603] "
                                          size={15}
                                        />
                                      </td>
                                      <td>
                                        <Trash2
                                          onClick={() =>
                                            confirmDelete(department.id)
                                          }
                                          className="mr-2 text-[#ef6603]"
                                          size={15}
                                        />
                                      </td>
                                    </div>
                                  </>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="2">No departments found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default MyDepartment
