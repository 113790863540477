import axios from 'axios';

const authorizeGetRequest = async (url) => {
  if (localStorage.token) {
    const axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
        'Content-Type': 'application/json',
      },
    });

    const baseURL = `${process.env.REACT_APP_API_URL}/${url}`;

    try {
      const response = await axiosInstance.get(baseURL);
      return response.data;
    } catch (error) {
      let res;
      if (error.response) {
        console.error('Server responded with an error:', error.response.data);
        throw error.response.data;
      } else if (error.request) {
        console.error('No response received:', error.request);
        res = { message: 'Network Error' };
        throw res;
      } else {
        console.error('Error setting up request:', error.message);
        res = { message: 'Something went wrong, please refresh the page' };
        throw res;
      }
    }
  } else {
    const res = { message: 'Login again to proceed' };
    throw res;
  }
};

export default authorizeGetRequest;
