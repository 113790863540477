import React from 'react'

const Footer = () => {
  return (
    <footer class="footer">
      <div class="container-fluid d-flex justify-content-between">
        <span class="text-muted d-block text-center text-sm-start d-sm-inline-block">{`Copyright © ${new Date().getFullYear()} Gataama Group`}</span>
      </div>
    </footer>
  )
}

export default Footer
