import React, { useState, useEffect, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import { Save, Clock, Trash2, ImageIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import { useAppContext } from '../DataContex'
import authorizePut from '../api/authorizedPut'

const EditCoreMember = () => {
  const navigate = useNavigate()
  const [menuStatus, setMenuStatus] = useState(false)
  const { member } = useAppContext()
  const [value, setValue] = useState('')
  const [name, setName] = useState('')
  const [profile, setProfilePicture] = useState('')
  const [position, setPosition] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [facebook, setFacebook] = useState('')
  const [twitter, setTwitter] = useState('')
  const [youtube, setYouTube] = useState('')
  const fileInputRef = useRef(null)
  const [Image, setImage] = useState('')

  useEffect(() => {
    if(member){
      setValue(member?.description)
      setName(member?.name)
      setProfilePicture(member?.profilePicture)
      setPosition(member?.position)
      setLinkedin(member?.linkedin)
      setFacebook(member?.facebook)
      setTwitter(member?.twitter)
      setYouTube(member?.youtube)
      setImage(`${process.env.REACT_APP_API_URL}` + member.profilePicture)

    }else{
      navigate('/core_team')
    }
  }, [member, navigate])

  // console.log('member: ', member)


  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean'],
  ]

  const modules = {
    toolbar: toolbarOptions,
  }

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  const handleEditMember = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('name', name)
    formData.append('description', value)
    formData.append('profilePicture', profile)
    formData.append('position', position)
    formData.append('linkedin', linkedin)
    formData.append('facebook', facebook)
    formData.append('twitter', twitter)
    formData.append('youtube', youtube)

    try {
      const response = await authorizePut(
        `team/main/${member.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      console.log('member updated successfully', response)
      navigate('/core_team')
    } catch (error) {
      console.log('Error updating member', error)
      toast.error('Error updating member')
    }
  }

  const handleImageAdd = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const addImage = async (viewImage) => {
    const file = viewImage.target.files?.[0]
    if (file) {
      setImage(URL.createObjectURL(file))
      setProfilePicture(file)
    }
  }


  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          <div className="content2">
            <div>
              <div className="md:flex md:gap-4">
                <section className="md:w-8/12 bg-white">
                  <div className="p-4">
                    <h4 className="card-title">EDIT CORE MEMBER</h4>
                    <form onSubmit={handleEditMember}>
                      <div className="flex gap-x-4">
                        <div className="w-full border-b-2 border-gray-500 mb-4">
                          <label htmlFor="sub-title" className="font-bold">
                            Name
                          </label>
                          <input
                            type="text"
                            placeholder="Member's Legal name"
                            className="w-full"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="w-full border-b-2 border-gray-500 mb-4">
                          <label htmlFor="sub-title" className="font-bold">
                            Position
                          </label>
                          <input
                            type="text"
                            placeholder="Member's position"
                            className="w-full"
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="h-[65vh] md:h-[55vh]">
                        <ReactQuill
                          modules={modules}
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          className="h-[40vh]"
                        />
                      </div>
                      <div className="">
                        <h2 className="font-bold mb-4">Socal Links</h2>
                        <div className="w-10/12 md:w-6/12 flex justify-between mb-2">
                          <label
                            htmlFor="name"
                            className="font-bold w-5/12 md:w-3/12"
                          >
                            Linkedin:
                          </label>
                          <div className="w-full border-b-2 border-gray-500">
                            <input
                              type="text"
                              placeholder="Add Linkedin handle"
                              className="w-full  "
                              value={linkedin}
                              onChange={(e) => setLinkedin(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="w-10/12 md:w-6/12 flex justify-between mb-2">
                          <label
                            htmlFor="name"
                            className="font-bold w-5/12 md:w-3/12"
                          >
                            Facebook:
                          </label>
                          <div className="w-full border-b-2 border-gray-500">
                            <input
                              type="text"
                              placeholder="Add Facebook username"
                              className="w-full"
                              value={facebook}
                              onChange={(e) => setFacebook(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="w-10/12 md:w-6/12 flex justify-between mb-2">
                          <label
                            htmlFor="name"
                            className="font-bold w-5/12 md:w-3/12"
                          >
                            Twitter:
                          </label>
                          <div className="w-full border-b-2 border-gray-500">
                            <input
                              type="text"
                              placeholder="Add X handle"
                              className="w-full"
                              value={twitter}
                              onChange={(e) => setTwitter(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="w-10/12 md:w-6/12 flex justify-between mb-2">
                          <label
                            htmlFor="name"
                            className="font-bold w-5/12 md:w-3/12"
                          >
                            Youtube:
                          </label>
                          <div className="w-full">
                            <input
                              type="text"
                              placeholder="Add Youtube handle"
                              className="w-full border-b-2 border-gray-500"
                              value={youtube}
                              onChange={(e) => setYouTube(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="my-4">
                        <h4 className="card-Image">Add Image</h4>
                        <div>
                          <div
                            className={`w-60 h-full flex items-center justify-center border-2 border-gray-300 rounded-lg mt-2 cursor-pointer rounded-md ${
                              Image ? 'p-0' : 'p-4'
                            }`}
                            onClick={handleImageAdd}
                          >
                            {Image ? (
                              <img
                                src={Image}
                                alt="thumbnail"
                                className="w-full rounded-md"
                              />
                            ) : (
                              <ImageIcon size={100} className="text-gray-300" />
                            )}
                          </div>
                          <input
                            ref={fileInputRef}
                            type="file"
                            name="image"
                            style={{ display: 'none' }}
                            accept=".jpeg,.png,.jpg"
                            onChange={addImage}
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="py-2 px-8 font-bold  shadow-md rounded-lg text-white  bg-[#ef6603] w-full md:w-40"
                      >
                        Update
                      </button>
                    </form>
                  </div>
                </section>
                <div className="flex w-full md:items-end md:w-4/12">
                  <section className="w-full h-[30vh] bg-white ">
                    <div className="">
                      <h4 className="card-title w-full bg-[#d8c0dd] text-white p-3">
                        More options
                      </h4>
                      <div className="px-2 ">
                        <div className="w-full grid">
                          <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg py-2 px-6 my-1 gap-x-2">
                            <Save size={20} />
                            Save Draft
                          </button>
                          <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg  py-2 px-6 my-1 gap-x-2">
                            <Clock size={20} />
                            Schedule
                          </button>
                          <button className="flex items-center justify-center bg-[#ef6603] text-white shadow-md rounded-lg  py-2 px-6 my-1 gap-x-2">
                            <Trash2 size={20} />
                            Discard
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default EditCoreMember
