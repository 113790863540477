import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { saveAs } from 'file-saver';
import authorizePatchRequest from '../api/authorizePatchRequest';

const VolunteerModal = ({ volunteer, changeStatus }) => {
  const [loading, setLoading] = useState(false)
  const [statusLoading, setStatusLoading] = useState(true)

  useEffect(() => {
    const getData = async () => {
      if (Number(volunteer.status) === 1) {
        await authorizePatchRequest(`contact/career/${volunteer.id}`, { status: 0 })
          .then(response => {
            // console.log('status', response.message);
            volunteer.status = 0
            changeStatus(0)
          })
          .catch((error) => {
            // console.log('error logging in', error)
          })
      }
    }
    getData()
  }, [volunteer])

  const handleDownload = async (url) => {
    setLoading(true)
    const fileName = Date.now().toString();
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    await link.click();
    document.body.removeChild(link);
    setLoading(false)
    
  };

  return (
    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-primary" id="exampleModalLabel">Volunteer Info</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <p><b>Name : </b>{volunteer.name}</p>
            <p><b>Email : </b>{volunteer.email}</p>
            <p><b>Phone : </b>{volunteer.phone}</p>
            <p><b>Message : </b>{volunteer.message}</p>
            <p><b>Country : </b>{volunteer.country}</p>
            <p><b>Date sent : </b>{format(new Date(volunteer.createdAt.split('T')[0]), 'PPP')}</p>
            <p><b>Status : </b><label class={volunteer.status === 1 ? "badge badge-gradient-success" : "badge badge-gradient-warning"}>{volunteer.status === 1 ? "New" : "Opened"}</label></p>

          </div>
          <div className="modal-footer">
            {loading ? (
              <div class="loader m-5 text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {Number(volunteer.status) === 0 && (
                  <button onClick={async () => {
                    setLoading(true)
                    await authorizePatchRequest(`contact/career/${volunteer.id}`, { status: 1 })
                      .then(response => {
                        // console.log('status', response.message);
                        volunteer.status = 1
                        changeStatus(1)
                        setLoading(false)
                      })
                      .catch((error) => {
                        // console.log('error logging in', error)
                        setLoading(false)
                      })
                  }} className="btn btn-warning">Mark as un opened</button>
                )}
                <button onClick={async () => await handleDownload(volunteer.cv)} className="btn btn-primary">Download CV</button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VolunteerModal
