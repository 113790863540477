import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import VolunteerModal from '../components/VolunteerModal'
import authorizeGetRequest from '../api/authorizeGetRequest'

const Volunteers = () => {
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [volunteer, setVolunteer] = useState({
    country: "",
    createdAt: "2024-02-22T05:54:37.000Z",
    cv: "",
    email: "",
    id: 0,
    message: "",
    name: "",
    phone: "",
    role: "",
    status: 0
  })

  useEffect(() => {
    const getData = async () => {
      await authorizeGetRequest(`contact/getVolunteers`)
        .then(response => {
          console.log('getVolunteers', response.volunteers);
          setData(response.volunteers)
          setLoading(false)
        })
        .catch((error) => {
          console.log('error logging in', error)
          setLoading(false)
        })
    }
    getData()
  }, [])

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }
  const changeStatus = (value) => {
    // setVolunteer(!menuStatus)
    // change status of volunteer data
  }

  return (
    <div class="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div class="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div class="main-panel">
          <div class="content-wrapper">
            {loading ? (
              <div class="loader m-5 text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col-12 grid-margin">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Volunteer List</h4>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th> Name </th>
                              <th> Country </th>
                              <th> Contact </th>
                              <th> Status </th>
                              <th> Date Added</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length >= 1 ? (
                              <>
                                {data.map((item, index) => {
                                  let dated = item.createdAt.split('T')[0]
                                  dated = format(new Date(dated), 'PPP')
                                  return (
                                    <tr style={{cursor: 'pointer'}} onClick={()=> setVolunteer(item)} data-bs-toggle="modal" data-bs-target="#exampleModal" key={index}>
                                      <td>{item.name}</td>
                                      <td>{item.country}</td>
                                      <td>{item.phone}</td>
                                      <td><label class={item.status === 1 ? "badge badge-gradient-success" : "badge badge-gradient-warning"}>{item.status === 1 ? "New" : "Opened"}</label></td>
                                      <td>{dated}</td>
                                    </tr>
                                  )
                                })}
                              </>
                            ) : (
                            <tr>
                              <td colSpan={5}>Volunteers will appear here</td>
                              </tr>
                            )}

                          </tbody>
                        </table>
                        <VolunteerModal volunteer={volunteer} changeStatus={changeStatus} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Volunteers
