import React, { useEffect, useState } from 'react'
import { X, Edit, Trash2 } from 'lucide-react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetRequest from '../api/authorizeGetRequest'
import authorizeDeleteRequest from '../api/authorizeDeleteRequest'
import authorizeUpdateRequest from '../api/authorizeUpdateRequest'
import authorizeCreate from '../api/authorizeCreate'
import toast, { Toaster } from 'react-hot-toast'

const Categories = () => {
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [create, setCreate] = useState(false)
  const [newCategory, setNewCategory] = useState('')
  const [edit, setEdit] = useState(false)
  const [currentCategory, setCurrentCategory] = useState({})
  const [conf, setConf] = useState(true)
  const [del, setDel] = useState('')

  const createCategory = (e) => {
    e.preventDefault()
    setCreate(true)
  }

  const closeCreate = (e) => {
    e.preventDefault()
    setCreate(false)
  }

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await authorizeGetRequest('categories')
        setData(response.data)
        setLoading(false)
      } catch (error) {
        console.log('Error fetching categories', error)
        toast.error('Error fetching categories')
        setLoading(false)
      }
    }
    getData()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const newCategoryData = await authorizeCreate('categories', {
        name: newCategory,
      })
      setData([...data, newCategoryData])
      setNewCategory('')
      setCreate(false)
    } catch (error) {
      console.log('Error creating category', error)
    }
  }

  const handleEdit = (category) => {
    setCurrentCategory(category)
    setEdit(true)
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    try {
      await authorizeUpdateRequest(
        `categories/${currentCategory.id}`,
        currentCategory,
      )
      setData(
        data.map((item) =>
          item.id === currentCategory.id ? currentCategory : item,
        ),
      )
      setEdit(false)
    } catch (error) {
      console.log('Error updating category', error)
    }
  }

  const confirmDelete = (id) => {
    setConf(true)
    setDel(id)
  }

  const handleDelete = async (id) => {
    try {
      await authorizeDeleteRequest(`categories/${id}`)
      setData(data.filter((item) => item.id !== id))
    } catch (error) {
      console.log('Error deleting category', error)
    }
  }

  const handleClose = () => {
    setConf(false)
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          {conf && (
            <>
              <section>
                {data.map((item) => (
                  <div key={item.id}>
                    {item.id === del && (
                      <section className="w-full flex justify-center">
                        <div className="fixed w-80 my-60 z-[999] bg-white  shadow-lg p-1 rounded-lg ">
                          <div className=" w-full flex justify-end">
                            <X onClick={handleClose} size={30} className="" />
                          </div>
                          <div className="text-center w-full px-4 pb-2">
                            <h2 className="">
                              Confirm to delete{' '}
                              <span className="text-red-500 font-bolder">
                                "{item.name}"
                              </span>
                            </h2>
                            <p className="italic text-sm text-red-500">
                              <span className="font-bold">Warning:</span> data
                              will not be recoverable after deletion!
                            </p>
                            <div className="w-full flex justify-center gap-x-4 my-2">
                              <div
                                onClick={handleClose}
                                className="p-2 px-4  text-gray-300 shadow-md font-bold rounded-lg cursor-pointer"
                              >
                                Cancel
                              </div>
                              <div
                                onClick={() => handleDelete(del)}
                                className="p-2 px-4 bg-[#ef6603] hover:bg-[#ef6603] text-white font-bold rounded-md cursor-pointer"
                              >
                                Confirm
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                ))}
              </section>
            </>
          )}

          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]">
            {loading ? (
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className=" px-2 py-4">
                      <div className="flex items-center justify-between">
                        <h4 className="card-title px-3">Category List</h4>
                        <button
                          className=" rounded-lg shadow-md py-2  bg-[#ef6603] text-white px-2 md:px-4 mx-4"
                          onClick={createCategory}
                        >
                          + Create category
                        </button>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> Name </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, index) => (
                                <tr className="flex justify-between border-b">
                                  <div key={index}>
                                    <td className="semi-bold text-xl">
                                      {item.name}
                                    </td>
                                  </div>
                                  <div className="hidden md:flex  ">
                                    <td>
                                      <button
                                        type="button"
                                        className=" text-white bg-[#ef6603] shadow-md rounded-lg flex items-last justify-last py-2 px-3 md:px-4"
                                        onClick={() => handleEdit(item)}
                                      >
                                        <Edit className="mr-2" size={15} /> Edit
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className=" text-white bg-[#ef6603]  shadow-md rounded-lg flex items-last justify-last py-2 px-3 md:px-4 hover:border-red-700"
                                        onClick={() => confirmDelete(item.id)}
                                      >
                                        <Trash2
                                          className="mr-2 text-white"
                                          size={15}
                                        />{' '}
                                        Delete
                                      </button>
                                    </td>
                                  </div>
                                  <div className="flex  md:hidden ">
                                    <td>
                                      <Edit
                                        onClick={() => handleEdit(item)}
                                        className="mr-2 text-[#ef6603] "
                                        size={15}
                                      />
                                    </td>
                                    <td>
                                      <Trash2
                                        onClick={() => confirmDelete(item.id)}
                                        className="mr-2 text-[#ef6603]"
                                        size={15}
                                      />
                                    </td>
                                  </div>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5}>No category available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {create && (
            <section className="fixed w-full md:w-[83%] h-[90vh] bg-black bg-opacity-10 backdrop-blur-sm">
              <div className="w-full flex justify-end">
                <X className="cursor-pointer mr-5 mt-2" onClick={closeCreate} />
              </div>
              <div className=" w-[90%] md:w-[30%] md:w-50 rounded-lg bg-white p-4 flex justify-center m-auto border border-black">
                <div className="w-full">
                  <h4 className="text-center text-lg">Create New Category</h4>
                  <form className="w-full" onSubmit={handleSubmit}>
                    <div className="w-full my-4 border-b border-black">
                      <label htmlFor="name">Category name:</label>
                      <input
                        type="text"
                        className="w-full"
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                      />
                    </div>
                    <button
                      type="submit"
                      className="w-full bg-[#ef6603] font-bold text-white hover:bg-[#ef6603] p-2"
                    >
                      Create
                    </button>
                  </form>
                </div>
              </div>
            </section>
          )}
          {edit && (
            <section className="fixed w-full md:w-[83%] h-[90vh] bg-black bg-opacity-10 backdrop-blur-sm">
              <div className="w-full flex justify-end">
                <X
                  className="cursor-pointer mr-5 mt-2"
                  onClick={() => setEdit(false)}
                />
              </div>
              <div className="w-[90%] md:w-[30%] md:w-50 rounded-lg bg-white p-4 flex justify-center m-auto border border-black">
                <div className="w-full">
                  <h4 className="text-center text-lg">Edit Category</h4>
                  <form className="w-full" onSubmit={handleEditSubmit}>
                    <div className="w-full my-4 border-b border-black">
                      <label htmlFor="name">Category name:</label>
                      <input
                        type="text"
                        className="w-full"
                        value={currentCategory.name}
                        onChange={(e) =>
                          setCurrentCategory({
                            ...currentCategory,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                    <button
                      type="submit"
                      className="w-full bg-[#ef6603] font-bold text-white hover:bg-[#ef6603] p-2"
                    >
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </section>
          )}
          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default Categories
