import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetRequest from '../api/authorizeGetRequest'
import authorizeCreate from '../api/authorizeCreate'
import authorizeUpdateRequest from '../api/authorizeUpdateRequest'
import authorizeDeleteRequest from '../api/authorizeDeleteRequest'
import toast, { Toaster } from 'react-hot-toast'

const AddNewAdmin = () => {
  const [menuStatus, setMenuStatus] = useState(false)
  const [fullNames, setFullNames] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('')
  const [currentFullNames, setCurrentFullNames] = useState('')
  const [currentEmail, setCurrentEmail] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [currentRole, setCurrentRole] = useState('')
  const [admins, setAdmins] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModeOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [currentAdmin, setCurrentAdmin] = useState(null)
  const [conf, setConf] = useState(false)
  const [del, setDel] = useState(null)

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!fullNames ||!email ||!password ||!role) {
      toast.error('Please fill in all fields')
      return
    }

    try {
      await authorizeCreate('user/register', {
        fullNames,
        email,
        password,
        role,
      })
      getData()
      setIsModalOpen(false)
      setIsEditModeOpen(false)
      toast.success('Admin created successfully')
    } catch (error) {
      console.log('Error saving admin', error)
      toast.error('Error saving admin')
    }
  }

  const handleEdit = (admin) => {
    setCurrentAdmin(admin.id)
    setCurrentFullNames(admin.fullNames)
    setCurrentEmail(admin.email)
    setCurrentPassword(admin.password)
    setCurrentRole(admin.role)
    setIsModalOpen(false)
    setIsEditModeOpen(true)
    console.log("password: ", admin.password)
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    try {
      await authorizeUpdateRequest(`user/profile/${currentAdmin}`, {
        fullNames: currentFullNames,
        email: currentEmail,
        // password: currentPassword,
        role: currentRole,
      })
      toast.success('Admin edited successfully')
      getData()
      setIsEditModeOpen(false)
    } catch (error) {
      console.log('Error editing admin', error)
      toast.error('Error editing admin')
    }
  }

  const confirmDelete = (id) => {
    setConf(true)
    setDel(id)
  }

  const handleDelete = async (id) => {
    try {
      await authorizeDeleteRequest(`user/${id}`)
      setAdmins(admins.filter((item) => item.id !== id))
      toast.success('Admin deleted successfully')
    } catch (error) {
      console.log('Error deleting admin', error)
      toast.error('Error deleting admin')
    } finally {
      setConf(false)
    }
  }

  const handleAddNewAdmin = async (e) => {
    e.preventDefault()
    try {
      await authorizeCreate('user/register', {
        fullNames,
        email,
        password,
        role,
      })
      toast.success('What is going on here?')
      setFullNames("")
      setEmail("")
      setPassword("")
      setRole("")

      //   setData([...data, newCategoryData])
    } catch (error) {
      console.log('Error creating category', error)
    }
    setIsEditMode(false)
    setIsModalOpen(true)
  }

  const getData = async () => {
    try {
      const response = await authorizeGetRequest('user/admins')
      setAdmins(response.admins)
      setLoading(false)
    } catch (error) {
      console.log('Error fetching admins', error)
      toast.error('Error fetching admins')
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold">Admins List</h2>
              <button
                onClick={handleAddNewAdmin}
                className="bg-orange-400 text-white py-2 px-4 rounded-lg hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                Add New Admin
              </button>
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md">
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Role
                    </th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {admins.map((admin) => (
                    <>
                      {conf && (
                        <div className="fixed inset-0 bg-transparent backdrop-blur-sm overflow-y-auto h-full w-full flex items-center justify-center">
                          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                            <h2 className="text-2xl font-semibold mb-6">
                              Confirm Delete
                            </h2>
                            <p className="mb-6">
                              Are you sure you want to delete{' '}
                              <span className="text-red-500 font-bold">
                                "{admin.fullNames}"
                              </span>{' '}
                              from the admin list?
                            </p>
                            <div className="flex justify-end space-x-4">
                              <button
                                type="button"
                                onClick={() => setConf(false)}
                                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDelete(del)}
                                className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      <tr key={admin.id} className="border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {admin.fullNames}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {admin.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {admin.role}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            onClick={() => handleEdit(admin)}
                            className="bg-orange-300 text-white py-1 px-3 rounded-lg mr-2 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => confirmDelete(admin.id)}
                            className="bg-orange-300 text-white py-1 px-3 rounded-lg hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>

            {isModalOpen && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                  <h2 className="text-2xl font-semibold mb-6">Add New Admin</h2>
                  <form onSubmit={handleSubmit} className="space-y-4 ">
                    <div className="flex flex-col">
                      <label
                        htmlFor="fullNames"
                        className="text-sm font-medium text-gray-700 mb-1"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="fullNames"
                        name="fullNames"
                        value={fullNames}
                        onChange={(e) => setFullNames(e.target.value)}
                        className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter admin name"
                        required
                      />
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor="email"
                        className="text-sm font-medium text-gray-700 mb-1"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter admin email"
                        required
                      />
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor="password"
                        className="text-sm font-medium text-gray-700 mb-1"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter password"
                        required={!isEditMode}
                      />
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor="role"
                        className="text-sm font-medium text-gray-700 mb-1"
                      >
                        Role
                      </label>
                      <select
                        id="role"
                        name="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                      >
                        <option value=''>--assign role--</option>
                        <option value="admin">Super Admin</option>
                        <option value="countryAdmin">Country Admin</option>
                      </select>
                    </div>

                    <div className="flex justify-end space-x-4">
                      <button
                        type="button"
                        onClick={() => setIsModalOpen(false)}
                        className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-[#ef6603] text-white py-2 px-4 rounded-lg hover:bg-opacity-70  focus:outline-none focus:ring-2 focus:ring-[#ef6603]"
                      >
                        Add Admin
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {isEditModalOpen && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                  <h2 className="text-2xl font-semibold mb-6">Edit Admin</h2>
                  <form onSubmit={handleEditSubmit} className="space-y-4 ">
                    <div className="flex flex-col">
                      <label
                        htmlFor="fullNames"
                        className="text-sm font-medium text-gray-700 mb-1"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="fullNames"
                        name="fullNames"
                        value={currentFullNames}
                        onChange={(e) => setCurrentFullNames(e.target.value)}
                        className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter admin name"
                        required
                      />
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor="email"
                        className="text-sm font-medium text-gray-700 mb-1"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={currentEmail}
                        onChange={(e) => setCurrentEmail(e.target.value)}
                        className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter admin email"
                        required
                      />
                    </div>

                    {/* <div className="flex flex-col">
                      <label
                        htmlFor="password"
                        className="text-sm font-medium text-gray-700 mb-1"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter password"
                      />
                    </div> */}

                    <div className="flex flex-col">
                      <label
                        htmlFor="role"
                        className="text-sm font-medium text-gray-700 mb-1"
                      >
                        Role
                      </label>
                      <select
                        id="role"
                        name="role"
                        value={currentRole}
                        onChange={(e) => setCurrentRole(e.target.value)}
                        className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                      >
                        <option value="admin">Super Admin</option>
                        <option value="countryAdmin">Country Admin</option>
                      </select>
                    </div>

                    <div className="flex justify-end space-x-4">
                      <button
                        type="button"
                        onClick={() => setIsEditModeOpen(false)}
                        className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-[#ef6603] text-white py-2 px-4 rounded-lg hover:bg-opacity-70  focus:outline-none focus:ring-2 focus:ring-[#ef6603]"
                      >
                        Edit Admin
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
        <Toaster />
      </div>
    </div>
  )
}

export default AddNewAdmin
