import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetRequest from '../api/authorizeGetRequest'

const Petitions = () => {
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }
  const changeStatus = (value) => {
    // setVolunteer(!menuStatus)
    // change status of volunteer data
  }

  useEffect(() => {
    const getData = async () => {
      await authorizeGetRequest(`contact/getPetitions`)
        .then(response => {
          console.log('getPetitions', response.petitions);
          setData(response.petitions)
          setLoading(false)
        })
        .catch((error) => {
          console.log('error logging in', error)
          setLoading(false)
        })
    }
    getData()
  }, [])

  return (
    <div class="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div class="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div class="main-panel">
          <div class="content-wrapper">
            {loading ? (
              <div class="loader m-5 text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col-12 grid-margin">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Petition List</h4>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th> Name </th>
                              <th> Country </th>
                              <th> Email </th>
                              <th> Contact </th>
                              <th> Address </th>
                              <th> Date Added</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length >= 1 ? (
                              <>
                                {data.map((item, index) => {
                                  let dated = item.createdAt.split('T')[0]
                                  dated = format(new Date(dated), 'PPP')
                                  return (
                                    <tr style={{cursor: 'pointer'}} onClick={()=> {}} data-bs-toggle="modal" data-bs-target="#exampleModal" key={index}>
                                      <td>{item.names}</td>
                                      <td>{item.country}</td>
                                      <td>{item.email}</td>
                                      <td>{item.phone}</td>
                                      <td>{item.address}</td>
                                      <td>{dated}</td>
                                    </tr>
                                  )
                                })}
                              </>
                            ) : (
                            <tr>
                              <td colSpan={5}>Petitions will appear here</td>
                              </tr>
                            )}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Petitions;
