import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppContext  } from '../DataContex';
import { useLocation } from 'react-router-dom';

const Sidebar = ({ menuStatus }) => {
  const { user } = useAppContext (); 

  // Define role-based menu items
  const menuItems = [
    {
      path: '/',
      title: 'Home',
      icon: 'mdi mdi-home',
      roles: ['admin', 'countryAdmin'], 
    },
    {
      path: '/donations',
      title: 'Donations',
      icon: 'mdi mdi-cash',
      roles: ['admin'], 
    },
    {
      path: '/volunteers',
      title: 'Volunteers',
      icon: 'mdi mdi-account-group-outline',
      roles: ['admin', 'countryAdmin'], 
    },
    {
      path: '/add-new-admin', 
      title: 'Add new admin',
      icon: 'mdi mdi-account',
      roles: ['admin' ], 
    },
    {
      path: '/jobs',
      title: 'Job applicants',
      icon: 'mdi mdi-briefcase-account-outline',
      roles: ['admin', 'countryAdmin'], 
    },
    {
      path: '/messages',
      title: 'Messages',
      icon: 'mdi mdi-message-text',
      roles: ['admin', 'countryAdmin'], 
    },
    {
      path: '/petitions',
      title: 'Petitions',
      icon: 'mdi mdi-pen',
      roles: ['admin', 'countryAdmin'], 
    },
    {
      path: '/room',
      title: 'Room',
      icon: 'mdi mdi-google-classroom',
      roles: ['admin', 'countryAdmin'], 
    },
    {
      path: '/categories',
      title: 'Category',
      icon: 'mdi mdi-view-list',
      roles: ['admin', 'countryAdmin'], 
    },
    {
      path: '/Blog',
      title: 'Blog',
      icon: 'mdi mdi-fountain-pen-tip',
      roles: ['admin', 'countryAdmin'], 
    },
    {
      path: '/countries',
      title: 'Country',
      icon: 'mdi mdi-web',
      roles: ['admin', 'countryAdmin'], 
    },
    {
      path: '/core_team',
      title: 'Core Team',
      icon: 'mdi mdi-account-group-outline',
      roles: ['admin'], 
    },
    {
      path: '/my_department',
      title: 'My Department',
      icon: 'mdi mdi-office-building',
      roles: ['admin', 'countryAdmin'], 
    },
    {
      path: '/teams',
      title: 'My Team',
      icon: 'mdi mdi-account-group-outline',
      roles: ['admin', 'countryAdmin'], 
    },
  ];

  // {({ isActive }) => isActive ? "nav-link active" : "nav-link"}

  let {pathname} = useLocation()

  return (
    <nav className={menuStatus ? "sidebar sidebar-offcanvas active" : "sidebar sidebar-offcanvas"} id="sidebar">
      <div className='pre-nav fixed overflow-y-scroll h-full pb-10'>
      <ul className="nav ">
        {menuItems
          .filter(item => item.roles.includes(user?.role)) 
          .map(item => (
            <li key={item.path} className="nav-item nav-item-specific w-full ">
              <NavLink to={item.path} className={`nav-link nav-item-specific gap-x-16 rounded-lg ${pathname === item.path ? 'bg-[#ef6603] text-white font-bold': 'bg-transparent'}`}>
                <span className="menu-title">{item.title}</span>
                <i className={`${item.icon} menu-icon`}></i>
              </NavLink>
            </li>
          ))}
      </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
