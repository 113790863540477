import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import toast, { Toaster } from 'react-hot-toast'
import authorizeGetRequest from '../api/authorizeGetRequest'
import { useAppContext } from '../DataContex'
import { useNavigate } from 'react-router-dom'
import { Users2Icon } from 'lucide-react'

const Teams = () => {
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const { user } = useAppContext()

  const navigate = useNavigate()

  const handleData = (id) => {
    localStorage.setItem('TeamID', id)
  }

  const sortData = (data, key) => {
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    })
  }

  const getData = async () => {
    try {
      const response = await authorizeGetRequest('countries')
      console.log('countries: ', response)
     handleCountryCheck(response)
    } catch (error) {
      console.error(error)
      toast.error('Failed to fetch countries ')
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const handleCountryCheck = (userCountry) => {
    const userCountries = userCountry.filter(count => count.admins.includes(user.id))
    console.log("All Countries", userCountry)
    console.log("User Country", userCountries)
    if(userCountries.length > 1) {
      setData(sortData(userCountries, 'name'))
      setLoading(false)
    }else if(userCountries.length === 1){
      localStorage.setItem("TeamID", userCountries[0].id)
      navigate('/team')
    }else{
      setLoading(false)
    }
  }

  

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]">
            {loading ? (
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className=" px-2 py-4">
                      <div className="flex items-center justify-between">
                        <h4 className="card-title px-3">Countries</h4>
                        <div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> All countries </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length >= 2 ? (
                              Array.isArray(data) &&
                              data.map((item, index) => (
                                <tr
                                  key={index}
                                  className="flex items-center justify-between border-b"
                                >
                                  <td className="semi-bold text-xl">
                                    <a
                                      href={`https://gataama.com/country/${item.id}/departments`}
                                      className="grid md:flex"
                                    >
                                      {item.name}
                                    </a>
                                  </td>

                                  <td className="hidden md:flex gap-x-4 items-center ">
                                    <a
                                      href={'/team'}
                                      onClick={() => handleData(item.id)}
                                      className="text-white bg-[#ef6603] w-full shadow-md rounded-lg !flex items-center justify-center py-2 px-3 gap-x-2 md:px-4"
                                    >
                                     
                                      <Users2Icon size={20} />{' '}
                                        Team
                                    </a>

                                    <div className="flex gap-x-4">
                                    </div>
                                  </td>
                                  <td className="flex md:hidden gap-x-4 items-center ">
                                    <a href={'/team'}>
                                    <Users2Icon size={20} onClick={() => handleData(item.id)} className="mr-2 text-[#ef6603]" />
                                    </a>
                                  </td>
                                </tr>
                              ))
                            ):(
                              <tr>
                                <td colSpan={5}>
                                  There is no team available
                                </td>
                              </tr>
                            )}
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default Teams
