import React, { useEffect, useState, useRef } from 'react'
import { X, Edit, Trash2, Image } from 'lucide-react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetRequest from '../api/authorizeGetRequest'
import authorizeDeleteRequest from '../api/authorizeDeleteRequest'
import toast, { Toaster } from 'react-hot-toast'
import { useAppContext } from '../DataContex'
import { useNavigate } from 'react-router-dom'

const Team = () => {
  const navigate = useNavigate()
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [conf, setConf] = useState(true)
  const [country, setCountry] = useState('')
  const [del, setDel] = useState('')


  const [TeamId] = localStorage.getItem('TeamID')
  const {setMember} = useAppContext()
  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  const handleEdit = (member) => {
    setMember(member)
    navigate('/edit_member')
  }
  

    const getData = async () => {
      try {
        const response = await authorizeGetRequest(`team/${TeamId}`)
        setData(response)
        console.log("response: ", response)
        setLoading(false)
      } catch (error) {
        console.log('Error fetching members', error)
        toast.error('Error fetching members')
        setLoading(false)
      }
    }

    useEffect(() => {
      getData()
      fetchCountryData()
    }, [])


  const confirmDelete = (id) => {
    setConf(true)
    setDel(id)
  }

  const handleDelete = async (id) => {
    try {
      await authorizeDeleteRequest(`team/${TeamId}/${id}`)
      setData(data.filter((item) => item.id !== id))
    } catch (error) {
      console.log('Error deleting member', error)
    }
  }

  const handleClose = () => {
    setConf(false)
  }

  const fetchCountryData = async () => {
    try {
      const response = await authorizeGetRequest(`countries/${TeamId}`)
      setCountry(response.name)
      
      console.log('Country: ', response)
    } catch (error) {
      console.log('Error fetching country', error)
      toast.error('Error fetching country')
    }
  }


  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          {conf && (
            <>
              <section>
                {data.map((item) => (
                  <div key={item.id}>
                    {item.id === del && (
                      <section className="w-full flex justify-center">
                        <div className="fixed w-80 my-60 z-[999] bg-white  shadow-lg p-1 rounded-lg ">
                          <div className=" w-full flex justify-end">
                            <X onClick={handleClose} size={30} className="" />
                          </div>
                          <div className="text-center w-full px-4 pb-2">
                            <h2 className="">
                              Confirm to delete{' '}
                              <span className="text-red-500 font-bolder">
                                "{item.name}"
                              </span>
                            </h2>
                            <p className="italic text-sm text-red-500">
                              <span className="font-bold">Warning:</span> data
                              will not be recoverable after deletion!
                            </p>
                            <div className="w-full flex justify-center gap-x-4 my-2">
                              <div
                                onClick={handleClose}
                                className="p-2 px-4  text-gray-300 shadow-md font-bold rounded-lg cursor-pointer"
                              >
                                Cancel
                              </div>
                              <div
                                onClick={() => handleDelete(del)}
                                className="p-2 px-4 bg-[#ef6603] hover:bg-[#ef6603] text-white font-bold rounded-md cursor-pointer"
                              >
                                Confirm
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                ))}
              </section>
            </>
          )}

          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]">
            {loading ? (
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className=" px-2 py-4">
                      <div className="flex items-center justify-between">
                        <h4 className="card-title px-3">Team {country}</h4>
                        <a
                        href={'/add_member'}
                          className=" rounded-lg shadow-md py-2  bg-[#ef6603] text-white text-center px-2 md:px-4 mx-4"
                        >
                          + Add Member
                        </a>
                      </div>
                      <section className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-x-4 gap-y-4 mt-4">
                        {data ?(
                          data.map(member => (
                          <section className='border rounded-md '>
                            <img src={`${process.env.REACT_APP_API_URL}` + member.profilePicture} alt={member.name} className='rounded-md w-100 h-60' />
                            <div className='flex justify-between bg-gray-100'>
                            <div className='w-full px-1 py-2'>
                            <div className=' flex'>
                              <p className='font-bold'>{member.name}</p>
                            </div>
                            <div className='flex'>
                            <p className='italic'>{member.position}</p>
                            </div>
                            </div>
                            <div className='flex items-center gap-x-2 mx-2 '>
                              <div  onClick={() => handleEdit(member)} >
                              <Edit size={20}
                              className='cursor-pointer '/>
                              </div>
                              <Trash2 onClick={() => confirmDelete(member.id)} size={20} className='cursor-pointer '/>
                            </div>
                            </div>
                          </section>
                        ))
                      ):(
                        <td colSpan={5}>There is no team member available</td>
                      )}
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default Team;
