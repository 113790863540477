import React, { useEffect, useState } from 'react'
import { Edit } from 'lucide-react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetRequest from '../api/authorizeGetRequest'
import toast, { Toaster } from 'react-hot-toast'
import authorizeUpdateRequest from '../api/authorizeUpdateRequest'

const Contact = () => {
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [country, setCountry] = useState('')
  const [email, setEmail] = useState('')
  const [location, setLocation] = useState('')
  const [phone, setPhone] = useState('')
  const [postalCode, setPostalCode] = useState('')

  const [ContactID] = localStorage.getItem('ContactID')
  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  const handleEdit = async (e) => {
    e.preventDefault()
      try{
        await authorizeUpdateRequest(`country-contact/${ContactID}`, {
          email,
          location,
          phone,
          postalCode,
        })
        toast.success('Contact information updated successfully')
      }catch(error){
        console.log("Error updating contact information: ", error)
        toast.error('Error updating contact information')
      }
  }

  const getData = async () => {
    try {
      const response = await authorizeGetRequest(`country-contact/${ContactID}`)
      setEmail(response.email)
      setLocation(response.location)
      setPhone(response.phone)
      setPostalCode(response.postalCode)
      console.log('response: ', response)
      setLoading(false)
    } catch (error) {
      console.log('Error fetching contact', error)
      toast.error('Error fetching contact')
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
    fetchCountryData()
  }, [])

  const fetchCountryData = async () => {
    try {
      const response = await authorizeGetRequest(`countries/${ContactID}`)
      setCountry(response.name)

      console.log('Country: ', response)
    } catch (error) {
      console.log('Error fetching country', error)
      toast.error('Error fetching country')
    }
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]">
            {loading ? (
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className=" px-2 py-4 h-[80vh]">
                      <div className="flex items-center justify-between">
                        <h4 className="md:text-lg text-sm px-3">{country} Contact</h4>
                        <div></div>
                      </div>
                      <section className='p-4'>
                        <form className='my-2' onSubmit={handleEdit}>
                            <div className='items-center gap-x-2'>
                        <div className='w-full my-3'>
                                <label htmlFor='email'>Country email:</label>
                                <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='email' className='w-full border-2 border rounded-lg p-2'/>
                            </div>
                            <div className='w-full my-3'>
                                <label htmlFor='location'>Location:</label>
                                <input type='text' value={location} onChange={(e) => setLocation(e.target.value)}  placeholder='Head office location' className='w-full border-2 border rounded-lg p-2'/>
                            </div>
                            </div>
                            <div className='md:flex items-center gap-x-2'>
                            <div className='w-full my-3'>
                                <label htmlFor='contact'>Country contact:</label>
                                <input type='tel' value={phone} onChange={(e) => setPhone(e.target.value)}  placeholder='phone' className='w-full border-2 border rounded-lg p-2'/>
                            </div>
                            <div className='w-full md:w-3/12 my-3'>
                                <label htmlFor='postal'>Postal code:</label>
                                <input type='text' value={postalCode} onChange={(e) => setPostalCode(e.target.value)} placeholder='Postal code' className='w-full border-2 border rounded-lg p-2'/>
                            </div>
                            </div>
                            <button 
                            type='submit'
                            className='w-full flex justify-center items-center bg-[#ef6603] text-white hover:text-[#ef6603] font-bold border-2 rounded-md hover:border-[#ef6603] hover:bg-transparent hover:text-[#ef6603] gap-x-2 p-2'
                            >
                              <Edit size={20} /> 
                              Edit Contact Information
                            </button>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default Contact
