import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import Error from './pages/Error';
import Login from './pages/Login';
import Blogs from './pages/Blogs';
import CreateBlog from './pages/CreateBlog';
import EditBlog from './pages/EditBlog';
import Volunteers from './pages/Volunteers';
import JobApplicants from './pages/JobApplicants';
import CreateJob from './pages/CreateJob'
import EditJob from './pages/EditJob'
import Messages from './pages/Messages';
import Petitions from './pages/Petitions';
import Room from './pages/Room';
import Categories from './pages/Categories';
import Countries from './pages/Countries';
import CountryDepartment from './pages/CountryDepartment';
import Department from './pages/Department';
import EditDepartment from './pages/EditDepartment';
import MyDepartment from './pages/MyDepartment';
import CoreTeam from './pages/CoreTeam';
import AddCoreMember from './pages/AddCoreMember';
import EditCoreMember from './pages/EditCoreMember';
import Teams from './pages/Teams';
import Team from './pages/Team';
import AddMember from './pages/AddMember';
import EditMember from './pages/EditMember';
import Contact from './pages/Contact';
import Start from './pages/Start';
import Unauthorized from './pages/Unauthorized';
import AddNewAdmin from './pages/AddNewAdmin';
import { AppProvider } from './DataContex'; 
import ProtectedRoute from './utils/ProtectedRoute';

const App = () => {
  const [data, setData] = useState([
    {
      path: "/",
      element: <ProtectedRoute element={<Start />} allowedRoles={['admin', 'countryAdmin']} />,
      errorElement: <Error />,
    },
    {
      path: "/add-new-admin",
      element: <ProtectedRoute element={<AddNewAdmin />} allowedRoles={['admin']} />,
      errorElement: <Error />,
    },
    {
      path: "/donations",
      element: <ProtectedRoute element={<Home />} allowedRoles={['admin']} />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/blog",
      element: <ProtectedRoute element={<Blogs />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/create_new_blog",
      element: <ProtectedRoute element={<CreateBlog />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/edit_blog",
      element: <ProtectedRoute element={<EditBlog />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/volunteers",
      element: <ProtectedRoute element={<Volunteers />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/jobs",
      element: <ProtectedRoute element={<JobApplicants />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/create_job",
      element: <ProtectedRoute element={<CreateJob />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/edit_job",
      element: <ProtectedRoute element={<EditJob />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/messages",
      element: <ProtectedRoute element={<Messages />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/petitions",
      element: <ProtectedRoute element={<Petitions />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/room",
      element: <ProtectedRoute element={<Room />} allowedRoles={['admin']} />,
    },
    {
      path: "/categories",
      element: <ProtectedRoute element={<Categories />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/countries",
      element: <ProtectedRoute element={<Countries />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/country_department",
      element: <ProtectedRoute element={<CountryDepartment />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/create_department",
      element: <ProtectedRoute element={<Department />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/edit_department",
      element: <ProtectedRoute element={<EditDepartment />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/my_department",
      element: <ProtectedRoute element={<MyDepartment />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/core_team",
      element: <ProtectedRoute element={<CoreTeam />} allowedRoles={['admin']} />,
    },
    {
      path: "/add_core_member",
      element: <ProtectedRoute element={<AddCoreMember />} allowedRoles={['admin']} />,
    },
    {
      path: "/edit_core_member",
      element: <ProtectedRoute element={<EditCoreMember />} allowedRoles={['admin']} />,
    },
    {
      path: "/teams",
      element: <ProtectedRoute element={<Teams />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/team",
      element: <ProtectedRoute element={<Team />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/add_member",
      element: <ProtectedRoute element={<AddMember />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/edit_member",
      element: <ProtectedRoute element={<EditMember />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/contact",
      element: <ProtectedRoute element={<Contact />} allowedRoles={['admin', 'countryAdmin']} />,
    },
    {
      path: "/unauthorized",
      element: <Unauthorized />,
    },
  ]);

  const router = createBrowserRouter(data);

  return (
    <AppProvider> 
      <RouterProvider router={router} />
    </AppProvider>
  );
}

export default App;

