import React, { Fragment, useEffect, useState } from 'react'
import { X, Edit, Trash2, Building } from 'lucide-react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import toast, { Toaster } from 'react-hot-toast'
import authorizeGetRequest from '../api/authorizeGetRequest'
import authorizeCreate from '../api/authorizeCreate'
import authorizeUpdateRequest from '../api/authorizeUpdateRequest'
import authorizeDeleteRequest from '../api/authorizeDeleteRequest'
import authorizedDeleteAdmin from '../api/authorizedDeleteAdmin'
import { useAppContext } from '../DataContex'

const Countries = () => {
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [admin, setAdmin] = useState([])
  const [create, setCreate] = useState(false)
  const [newCountry, setNewCountry] = useState('')
  const [edit, setEdit] = useState(false)
  const [currentCountry, setCurrentCountry] = useState({})
  const [conf, setConf] = useState(false)
  const [del, setDel] = useState('')
  const [depAdmins, setDepAdmins] = useState([])
  const [newAdmin, setNewAdmin] = useState('')
  const [reAssign, setReAssign] = useState(false)
  const [newAssAdmin, setNewASSAdmin] = useState('')
  const [adminBeingEdited, setAdminBeingEdited] = useState(null)
  const [deletingAdmin, setDeletingAdmin] = useState(false)
  const [confDeleteAdmin, setConfDeleteAdmin] = useState('')

  const { user } = useAppContext()

  const handleData = (id) => {
    localStorage.setItem('ID', id)
  }

  const sortData = (data, key) => {
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    })
  }

  const getData = async () => {
    try {
      const response = await authorizeGetRequest('countries')
      setData(sortData(response, 'name'))
      console.log('countries: ', response)
      setLoading(false)
    } catch (error) {
      console.error(error)
      toast.error('Failed to fetch countries')
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
    getAdmin()
  }, [])

  const getAdmin = async () => {
    try {
      const response = await authorizeGetRequest('user/admins')
      setAdmin(response)
      // console.log("Admins: ",response)
      setLoading(false)
    } catch (error) {
      console.error(error)
      toast.error('Failed to fetch admins')
      setLoading(false)
    }
  }

  // console.log('admins ad: ', admin.admins)

  const createCountry = (e) => {
    e.preventDefault()
    setCreate(true)
  }

  const closeCreate = (e) => {
    e.preventDefault()
    setCreate(false)
  }

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await authorizeCreate('countries', {
        name: newCountry,
        adminId: parseInt(depAdmins),
      })
      setNewCountry('')
      setDepAdmins('')
      toast.success('Country created successfully')
      setCreate(false)
      getData()
    } catch (error) {
      console.log('Error creating country', error)
    }
  }

  const handleEdit = (country) => {
    setCurrentCountry(country)
    setDepAdmins(country.admins)
    setEdit(true)
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    try {
      if (reAssign) {
        const updatedCountry = {
          name: currentCountry.name,
          currentAdminId: parseInt(adminBeingEdited),
          newAdminId: parseInt(newAssAdmin),
        }

        await authorizeUpdateRequest(
          `countries/${currentCountry.id}`,
          updatedCountry,
        )

        console.log('updatedCountry: ', updatedCountry)
        setData(
          data.map((item) =>
            item.id === currentCountry.id
              ? { ...item, ...updatedCountry }
              : item,
          ),
        )
        setNewCountry('')
        setDepAdmins('')
        setNewASSAdmin('')
        setNewAdmin('')
        setAdminBeingEdited(null)
        setReAssign(false)
      } else {
        const updatedCountry = {
          name: currentCountry.name,
          addAdminId: parseInt(newAdmin),
        }

        await authorizeUpdateRequest(
          `countries/${currentCountry.id}`,
          updatedCountry,
        )

        console.log('updatedCountry: ', updatedCountry)
        setData(
          data.map((item) =>
            item.id === currentCountry.id
              ? { ...item, ...updatedCountry }
              : item,
          ),
        )
        setNewCountry('')
        setDepAdmins('')
        setNewAdmin('')
        setAdminBeingEdited(null)
        setReAssign(false)
      }

      getData()
    } catch (error) {
      console.log('Error assigning country admin', error)
      toast.error('Failed to Assig country admin')
    }
  }

  const handleClose = () => {
    setConf(false)
    setNewCountry('')
    setDepAdmins('')
  }

  const confirmDelete = (id) => {
    setConf(true)
    setDel(id)
  }

  const handleDelete = async (id) => {
    try {
      await authorizeDeleteRequest(`countries/${id}`)
      setData(data.filter((item) => item.id !== id))
    } catch (error) {
      console.log('Error deleting country')
      toast.error('error deleting country')
    }
  }

  const handleEditAdmin = (id) => {
    setReAssign(true)
    setAdminBeingEdited(id)
  }

  const handleComfirmDeleteAdmin = (id) => {
    setDeletingAdmin(true)
    setConfDeleteAdmin(id)
  }

  const handleDeleteAdmin = async (id, country) => {
    console.log('Current', country)
    console.log('Admin ID', id)

    const deleteID = {
      removeAdminId: parseInt(id),
    }
    try {
      await authorizedDeleteAdmin(`countries/remove-admin/${country}`, deleteID)
      toast.success('Admin removed successfully')
      setData(data.filter((item) => item.admins.indexOf(id) === -1))

      getData()
    } catch (error) {
      console.log('Error deleting admin:', error)
      toast.error('Error deleting admin')
    }
    // setEdit(false)
    setNewCountry('')
    setDepAdmins('')
    setDeletingAdmin(false)
    setNewAdmin('')
    setAdminBeingEdited(null)
    setReAssign(false)
  }

  const closeDeleteAdmin = () => {
    setDeletingAdmin(false)
    setNewCountry('')
    setDepAdmins('')
    setDeletingAdmin(false)
    setNewAdmin('')
    setAdminBeingEdited(null)
    setReAssign(false)
  }

  const handleCloseEdit = () => {
    setEdit(false)
    setDeletingAdmin(false)
    setNewCountry('')
    setDepAdmins('')
    setDeletingAdmin(false)
    setNewAdmin('')
    setAdminBeingEdited(null)
    setReAssign(false)
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          {conf && (
            <>
              <section className="z-[99999]">
                {data.map((item) => (
                  <div key={item.id}>
                    {item.id === del && (
                      <section className="w-full flex justify-center">
                        <div className="fixed w-80 my-60 z-[999] bg-white  shadow-lg p-1 rounded-lg ">
                          <div className=" w-full flex justify-end">
                            <X onClick={handleClose} size={30} className="" />
                          </div>
                          <div className="text-center w-full px-4 pb-2">
                            <h2 className="">
                              Confirm to delete{' '}
                              <span className="text-red-500 font-bolder">
                                "{item.name}"
                              </span>
                            </h2>
                            <p className="italic text-sm text-red-500">
                              <span className="font-bold">Warning:</span> data
                              will not be recoverable after deletion!
                            </p>
                            <div className="w-full flex justify-center gap-x-4 my-2">
                              <div
                                onClick={handleClose}
                                className="p-2 px-4  text-gray-300 shadow-md font-bold rounded-lg cursor-pointer"
                              >
                                Cancel
                              </div>
                              <div
                                onClick={() => handleDelete(del)}
                                className="p-2 px-4 bg-[#ef6603] hover:bg-[#ef6603] text-white font-bold rounded-md cursor-pointer"
                              >
                                Confirm
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                ))}
              </section>
            </>
          )}
          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]">
            {loading ? (
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className=" px-2 py-4">
                      <div className="flex items-center justify-between">
                        <h4 className="card-title px-3">Countries</h4>
                        <div>
                          {user.role === 'admin' && (
                            <button
                              className=" rounded-lg shadow-md py-2  bg-[#ef6603] text-white px-2 md:px-4 mx-4"
                              onClick={createCountry}
                            >
                              + Add Country
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> All countries </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              Array.isArray(data) &&
                              data.map((item, index) => (
                                <tr
                                  key={index}
                                  className="flex items-center justify-between border-b"
                                >
                                  <td className="semi-bold text-xl">
                                    <a
                                      href={`https://gataama.com/country/${item.id}/departments`}
                                      className="grid md:flex"
                                    >
                                      {item.name}
                                    </a>
                                  </td>

                                  <td className="hidden md:flex gap-x-4 items-center ">
                                    <div className="italic text-sm text-gray-500 md:mr-40 flex flex-col text-start">
                                      {item.admins.length > 0 ? (
                                        <Fragment>
                                          {item.admins.map((adminId, ind) => {
                                            if (!Array.isArray(admin?.admins)) {
                                              return null
                                            }

                                            const adminIndex = admin.admins.findIndex(
                                              (adm) => adm.id === adminId,
                                            )

                                            return adminIndex !== -1 ? (
                                              <div key={ind}>
                                                Admin:{' '}
                                                {
                                                  admin.admins[adminIndex]
                                                    ?.fullNames
                                                }
                                              </div>
                                            ) : null
                                          })}
                                        </Fragment>
                                      ) : (
                                        <>No admin</>
                                      )}
                                    </div>
                                    <a
                                      href={'country_department'}
                                      onClick={() => handleData(item.id)}
                                      className="text-white bg-[#ef6603] w-full shadow-md rounded-lg flex items-center justify-center py-2 px-3 md:px-4"
                                    >
                                      <div className="flex">
                                        <Building className="mr-2" size={15} />{' '}
                                        Departments
                                      </div>
                                    </a>

                                    <div className="flex gap-x-4">
                                      {user.role === 'admin' && (
                                        <>
                                          <button
                                            type="button"
                                            className="text-white bg-[#ef6603] shadow-md rounded-lg flex items-last justify-last py-2 px-3 md:px-4"
                                            onClick={() => handleEdit(item)}
                                          >
                                            <Edit className="mr-2" size={15} />{' '}
                                            Edit
                                          </button>
                                          <button
                                            type="button"
                                            className="text-white bg-[#ef6603] shadow-md rounded-lg flex items-last justify-last py-2 px-3 md:px-4"
                                            onClick={() =>
                                              confirmDelete(item.id)
                                            }
                                          >
                                            <Trash2
                                              className="mr-2 text-white"
                                              size={15}
                                            />{' '}
                                            Delete
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td className="flex md:hidden gap-x-4 items-center ">
                                    <div className="italic text-sm text-gray-500 md:mr-40">
                                      {item.admins.length > 0 ? (
                                        <>
                                          {item.admins.map((adminId, ind) => {
                                            if (!Array.isArray(admin?.admins)) {
                                              return null
                                            }

                                            const adminIndex = admin.admins.findIndex(
                                              (adm) => adm.id === adminId,
                                            )

                                            return adminIndex !== -1 ? (
                                              <div key={ind}>
                                                Admin:{' '}
                                                {
                                                  admin.admins[adminIndex]
                                                    ?.fullNames
                                                }
                                              </div>
                                            ) : null
                                          })}
                                        </>
                                      ) : (
                                        <>No admin</>
                                      )}
                                    </div>
                                    <a href={'country_department'}>
                                      <Building
                                        onClick={() => handleData(item.id)}
                                        className="mr-2 text-[#ef6603]"
                                        size={15}
                                      />
                                    </a>
                                    <div className="flex gap-x-4">
                                      {user.role === 'admin' && (
                                        <>
                                          <Edit
                                            onClick={() => handleEdit(item)}
                                            className="mr-2 text-blue-500"
                                            size={15}
                                          />
                                          <Trash2
                                            onClick={() =>
                                              confirmDelete(item.id)
                                            }
                                            className="mr-2 text-red-500"
                                            size={15}
                                          />
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5}>
                                  There is no country available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {create && (
            <section className="fixed w-full md:w-[83%] h-[90vh] bg-black bg-opacity-10 backdrop-blur-sm">
              <div className="w-full flex justify-end">
                <X className="cursor-pointer mr-5 mt-2" onClick={closeCreate} />
              </div>
              <div className=" w-[90%] md:w-[30%] md:w-50 rounded-lg bg-white p-4 flex justify-center m-auto border border-black">
                <div className="w-full">
                  <h4 className="text-center text-lg">Add New Country</h4>
                  <form className="w-full" onSubmit={handleSubmit}>
                    <div className="w-full my-4 border-b border-black">
                      <label htmlFor="name">Country name:</label>
                      <input
                        type="text"
                        className="w-full"
                        value={newCountry}
                        onChange={(e) => setNewCountry(e.target.value)}
                      />
                    </div>
                    <div className="w-full my-4 border-b border-black">
                      <label htmlFor="name">Department Admin:</label>
                      <select
                        value={depAdmins}
                        onChange={(e) => setDepAdmins(e.target.value)}
                        className="w-full"
                      >
                        <option value="">Select an admin</option>
                        {admin.admins.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.fullNames}
                          </option>
                        ))}
                      </select>
                    </div>
                    {newCountry !== '' ? (
                      <button
                        type="submit"
                        className="w-full bg-green-500 font-bold text-white p-2"
                      >
                        Create
                      </button>
                    ) : (
                      <div
                        className="w-full bg-[#ef6603] text-center font-bold text-white p-2 cursor-not-allowed"
                        title="Country name is currently empty"
                      >
                        Create
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </section>
          )}
          {edit && (
            <section className="fixed w-full md:w-[83%] h-[90vh] bg-black bg-opacity-10 backdrop-blur-sm">
              <div className="w-full flex justify-end">
                <X
                  className="cursor-pointer mr-5 mt-2"
                  onClick={handleCloseEdit}
                />
              </div>
              <div className="w-[90%] md:w-[30%] rounded-lg bg-white p-4 flex justify-center m-auto border border-black">
                <div className="w-full">
                  {deletingAdmin && (
                    <section className="fixed w-[78vw] md:w-[38.5vw] h-[80vw] md:h-[37.5vh] bg-blue-500 bg-opacity-10 backdrop-blur-sm border border-gray-500 p-2 rounded-md">
                      <div className="w-full flex justify-end">
                        <X
                          onClick={closeDeleteAdmin}
                          className="cursor-pointer"
                        />
                      </div>
                      {admin.admins.map((admin) => (
                        <div key={admin}>
                          {confDeleteAdmin === admin.id && (
                            <div className="w-full px-2 py-10 md:px-10">
                              <div className="w-full text-center p-6 bg-white rounded-lg">
                                <h4>
                                  Are you sure you want to remove "
                                  <span className="italic underline">
                                    {admin.fullNames}
                                  </span>
                                  " as an admin for "
                                  <span className="italic underline">
                                    {currentCountry.name}
                                  </span>
                                  "?
                                </h4>
                                <div className="flex justify-center mt-4 gap-x-4">
                                  <button
                                    onClick={closeDeleteAdmin}
                                    className="p-2 px-4  text-gray-500 shadow-md font-bold rounded-lg cursor-pointer border-gray-500 hover:bg-green-500"
                                  >
                                    No
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleDeleteAdmin(
                                        admin.id,
                                        currentCountry.id,
                                      )
                                    }
                                    className="p-2 px-4 bg-[#ef6603] hover:bg-[#ef6603] text-white font-bold rounded-md cursor-pointer"
                                  >
                                    Yes
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </section>
                  )}

                  <h4 className="text-center text-lg">Edit Country</h4>
                  <form className="w-full" onSubmit={handleEditSubmit}>
                    <div className="w-full my-4 border-b border-black">
                      <label htmlFor="name">Country name:</label>
                      <input
                        type="text"
                        className="w-full"
                        value={currentCountry.name}
                        onChange={(e) =>
                          setCurrentCountry({
                            ...currentCountry,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="w-full my-4">
                      <label htmlFor="admins">Department Admins:</label>
                      {Array.isArray(depAdmins) &&
                        depAdmins.map((adminId) => (
                          <section key={adminId}>
                            {admin.admins.map((admi) => (
                              <div key={admi.id}>
                                {admi.id === adminId && (
                                  <div>
                                    <div className="flex items-center justify-between w-full">
                                      <p>{admi.fullNames}</p>
                                      <p className="flex items-center gap-x-4">
                                        <Edit
                                          onClick={() =>
                                            handleEditAdmin(admi.id)
                                          }
                                          className="mr-2 text-blue-500 cursor-pointer"
                                          size={15}
                                        />
                                        <Trash2
                                          onClick={() =>
                                            handleComfirmDeleteAdmin(admi.id)
                                          }
                                          className="mr-2 text-red-500 cursor-pointer"
                                          size={15}
                                        />
                                      </p>
                                    </div>
                                    {adminBeingEdited === admi.id && (
                                      <div className="w-full flex items-center gap-x-2 my-4">
                                        <input
                                          type="text"
                                          value={admi.fullNames}
                                          className="w-6/12 border-b border-black focus:outline-none"
                                        />
                                        <select
                                          value={newAssAdmin}
                                          onChange={(e) =>
                                            setNewASSAdmin(e.target.value)
                                          }
                                          className="w-6/12 border-b border-black"
                                        >
                                          <option value="">Change admin</option>
                                          {admin.admins.map((admin) => (
                                            <option
                                              key={admin.id}
                                              value={admin.id}
                                            >
                                              {admin.fullNames}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                          </section>
                        ))}
                      {!reAssign && (
                        <select
                          value={newAdmin}
                          onChange={(e) => setNewAdmin(e.target.value)}
                          className="w-full my-2 border-b border-black"
                        >
                          <option value="">Add admin</option>
                          {admin.admins.map((admin) => (
                            <option key={admin.id} value={admin.id}>
                              {admin.fullNames}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    {reAssign ? (
                      <button
                        type="submit"
                        className="w-full bg-blue-500 font-bold text-white hover:bg-blue-400 p-2"
                      >
                        Update Admin
                      </button>
                    ) : (
                      <>
                        {newAdmin === '' ? (
                          <div
                            className="w-full text-center bg-[#ef6603] font-bold text-white p-2 cursor-not-allowed"
                            title="No admin selected"
                          >
                            Add Admin
                          </div>
                        ) : (
                          <button
                            type="submit"
                            className="w-full bg-green-500 font-bold text-white p-2"
                          >
                            Add Admin
                          </button>
                        )}
                      </>
                    )}
                  </form>
                </div>
              </div>
            </section>
          )}

          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default Countries
