import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetRequest from '../api/authorizeGetRequest'

const Messages = () => {
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [message, setMessage] = useState('')

  useEffect(() => {
    const getData = async () => {
      await authorizeGetRequest(`contact/getMessages`)
        .then(response => {
          // console.log('getMessages', response.messages);
          setData(response.messages)
          setLoading(false)
        })
        .catch((error) => {
          console.log('error logging in', error)
          setLoading(false)
        })
    }
    getData()
  }, [])

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  return (
    <div class="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div class="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div class="main-panel">
          <div class="content-wrapper">
          {loading ? (
              <div class="loader m-5 text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
          <div class="row">
              <div class="col-md-6 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Messages</h4>
                    <div class="template-demo">
                    {data.length >= 1 ? (
                      <>
                      {data.map((item, index) => (
                        <h4 key={index} style={{cursor: 'pointer'}} onClick={()=> setMessage(item)}>{item.name}</h4>
                      ))}
                      </>
                    ) : (
                      <h4>Your messages will appear here </h4>
                    )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    {message ? (
                      <>
                        <h4 class="card-title">Message Info</h4>
                    <div class="template-demo">
                      <h4>Name : <small class="text-muted"> {message.name} </small></h4>
                      <h4>Email : <small class="text-muted"> {message.email} </small></h4>
                      <h4>Subject : <small class="text-muted"> {message.subject} </small></h4>
                      <h4>Message : <small class="text-muted"> {message.message} </small></h4>
                      <h4>Date created : <small class="text-muted"> {format(new Date(message.createdAt.split('T')[0]), 'PPP')} </small></h4>
                    </div>
                      </>
                    ) : <h4 class="card-title">Message Info will appear here</h4>}
                    
                  </div>
                </div>
              </div>
          </div>
          )}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Messages
