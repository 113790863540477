import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetRequest from '../api/authorizeGetRequest'

const Home = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    wallets: [],
    transactions: []
  })
  const [menuStatus, setMenuStatus] = useState(false)

  useEffect(() => {
    const getData = async () => {
      const tk = await localStorage.token
      const us = await localStorage.user

      console.log(us);
      
      console.log(tk)
      if (!tk) {
        return navigate("/login");
      }
      await authorizeGetRequest(`donate/adminAnalytics`)
        .then(response => {
          // console.log('wallets/transactions', response);
          setData(response)
          setLoading(false)
        })
        .catch((error) => {
          // console.log('error logging in', error)
          setLoading(false)
        })
    }
    getData()
  }, [])

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          <div className="content-wrapper">
            {loading ? (
              <div class="loader m-5 text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <div class="row">
                  {data.wallets.length > 1 ? (
                    <>
                      {data.wallets.map((item, index) => (
                        <div key={index} class="col-md-4 stretch-card grid-margin">
                          <div class="card bg-gradient-danger card-img-holder text-white">
                            <div class="card-body">
                              <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
                              <h4 class="font-weight-normal mb-3">{item.currency}</h4>
                              <h2 class="mb-2">{item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <p>Account Balances will appear here</p>
                  )}
                </div>
                <div class="row">
                  <div class="col-12 grid-margin">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title">Recent Donations</h4>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th> Name </th>
                                <th> Amount </th>
                                <th> Reason </th>
                                <th> Transaction ID </th>
                                <th> Date </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.transactions.length > 1 ? (
                                <>
                                  {data.transactions.map((item, index) => {
                                    let donationtype = ''
                                    if (item.donationType === 'general') {
                                      donationtype = 'General'
                                    } else if (item.donationType === 'tourism') {
                                      donationtype = 'Tourism & Agriculture'
                                    } else if (item.donationType === 'health') {
                                      donationtype = 'Health & Social Care'
                                    } else if (item.donationType === 'transport') {
                                      donationtype = 'Transport'
                                    } else if (item.donationType === 'work') {
                                      donationtype = 'Work & Pensions'
                                    } else if (item.donationType === 'environment') {
                                      donationtype = 'Environment, Food & Rural Affairs'
                                    } else if (item.donationType === 'energy') {
                                      donationtype = 'Energy, Security & Net Zero'
                                    } else if (item.donationType === 'culture') {
                                      donationtype = 'Culture, Media & Sport'
                                    } else if (item.donationType === 'business') {
                                      donationtype = 'Business & Trade'
                                    } else if (item.donationType === 'education') {
                                      donationtype = 'Education'
                                    } else if (item.donationType === 'tech') {
                                      donationtype = 'Technology & Innovations'
                                    } else if (item.donationType === 'truth') {
                                      donationtype = 'Truth & Justice'
                                    } else if (item.donationType === 'humanRights') {
                                      donationtype = 'Human Rights'
                                    } else if (item.donationType === 'product') {
                                      donationtype = 'Product'
                                    }
                                    let dated = item.createdAt.split('T')[0]
                                    dated = format(new Date(dated), 'PPP')
                                    console.log('transactions', item)
                                    return (
                                      <tr key={index}>
                                        <td>{item.fullNames}</td>
                                        <td>{`${item.currency} ${item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                                        <td>{donationtype}</td>
                                        <td>{item.transactionId}</td>
                                        <td>{dated}</td>
                                      </tr>
                                    )
                                  })}
                                </>
                              ) : (
                                <p>Donation history will appear here</p>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Home
