import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import authorizeGetPost from '../api/authorizeGetPost'
import authorizeDeleteRequest from '../api/authorizeDeleteRequest'
import authorizePut from '../api/authorizedPut'
import { BookDashed, BookOpenCheck, Edit, Trash2, X } from 'lucide-react'
import toast, { Toaster } from 'react-hot-toast'
import { useAppContext } from '../DataContex'

const Blog = () => {
  const navigate = useNavigate()
  const [menuStatus, setMenuStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [conf, setConf] = useState(true)
  const [del, setDel] = useState('')

  const { setBlogData, user } = useAppContext()

  const createBlog = (e) => {
    e.preventDefault()
    navigate('/create_new_blog')
  }

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  
    const getData = async () => {
      try {
        const response = await authorizeGetPost('posts')
        setData(response)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching posts:', error)
        toast.error('Error fetching posts')
        setLoading(false)
      }
    }

    useEffect(() => {
      getData()
    }, [])
  

  const handleEdit = (id) => {
    navigate(`/edit_blog`)
    setBlogData(id)
  }

  const confirmDelete = (id) => {
    setConf(true)
    setDel(id)
  }

  const handleDelete = async (id) => {
    try {
      await authorizeDeleteRequest(`posts/${id}`)
      setData(data.filter((item) => item.id !== id))
    } catch (error) {
      console.log('Error deleting post', error)
      toast.error('Error deleting post')
    }
  }

  const handleClose = () => {
    setConf(false)
  }

  const handlePublish = async (id) => {
    try {
      await authorizePut(`posts/publish/${id}`, {
        published: true,
      })

      getData()

      toast.success('Successfully published post')
    } catch (error) {
      console.log('Error publishing post', error)
      toast.error('Error publishing post')
    }
  }

  const handleUnpublish = async (id) => {
    try {
      await authorizePut(`posts/publish/${id}`, {
        published: false,
      })

      getData()

      toast.success('Successfully unpublished post')
    } catch (error) {
      console.log('Error unpublishing post', error)
      toast.error('Error unpublishing post')
    }
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
          {conf && (
            <>
              <section>
                {data.map((item) => (
                  <div key={item.id}>
                    {item.id === del && (
                      <section className="w-full flex justify-center">
                        <div className="fixed w-80 my-60 z-[999] bg-white shadow-lg p-1 rounded-md ">
                          <div className=" w-full flex justify-end">
                            <X onClick={handleClose} size={30} className="" />
                          </div>
                          <div className="text-center w-full px-4 pb-2">
                            <h2 className="">
                              Confirm to delete{' '}
                              <span className="text-red-500 font-bolder">
                                "{item.title}"
                              </span>
                            </h2>
                            <p className="italic text-sm text-red-500">
                              <span className="font-bold">Warning:</span> data
                              will not be recoverable after deletion!
                            </p>
                            <div className="w-full flex justify-center gap-x-4 my-2">
                              <div
                                onClick={handleClose}
                                className="p-2 px-4  text-gray-300 shadow-lg  font-bold rounded-md cursor-pointer"
                              >
                                Cancel
                              </div>
                              <div
                                onClick={() => handleDelete(del)}
                                className="p-2 px-4 bg-[#ef6603] hover:bg-[#ef6603] text-white font-bold rounded-md cursor-pointer"
                              >
                                Confirm
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                ))}
              </section>
            </>
          )}

          <div className="pt-2 md:p-6 h-full bg-[#f2edf3]">
            {loading ? (
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className=" px-2 py-4">
                      <div className="flex items-center justify-between">
                        <h4 className="card-title px-3">Blog</h4>
                        <button
                          className=" shadow-lg rounded-md bg-[#ef6603] text-white py-2 px-4 mx-4"
                          onClick={createBlog}
                        >
                          + Create blog
                        </button>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Recent Blogs</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {data.length > 0 ? (
                              data.map((item, index) => (
                                <tr
                                  style={{ cursor: 'pointer' }}
                                  key={index}
                                  data-bs-target="#exampleModal"
                                  className="flex items-center justify-between border-b"
                                >
                                  <a
                                    href={`https://gataama.com/blog/${item.id}`}
                                  >
                                    <td>{item.title}</td>
                                  </a>
                                  <div className="hidden md:flex items-center">
                                    <td className="italic mr-40">
                                      {item.published ? (
                                        <p className="text-green-500">
                                          published
                                        </p>
                                      ) : (
                                        <p className="text-red-500">
                                          Not published
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {user.role === 'admin' && (
                                        <>
                                          <div className="hidden md:flex">
                                            <td
                                              className={`${
                                                user.role !== 'admin' &&
                                                'hidden'
                                              }`}
                                            >
                                              {item.published === true ? (
                                                <button
                                                  type="button"
                                                  className={`bg-red-500 text-white rounded-lg shadow-md  flex items-center justify-center py-2 px-3 md:px-4`}
                                                  onClick={() =>
                                                    handleUnpublish(item.id)
                                                  }
                                                >
                                                  <BookDashed
                                                    className="mr-2"
                                                    size={15}
                                                  />{' '}
                                                  Unpublish
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className={`bg-[#ef6603] text-white rounded-lg shadow-md  flex items-center justify-center py-2 px-3 md:px-4`}
                                                  onClick={() =>
                                                    handlePublish(item.id)
                                                  }
                                                >
                                                  <BookOpenCheck
                                                    className="mr-2"
                                                    size={15}
                                                  />{' '}
                                                  Publish
                                                </button>
                                              )}
                                            </td>
                                          </div>
                                          <div className="flex  md:hidden ">
                                            <td>
                                              <Edit
                                                onClick={() => handleEdit(item)}
                                                className="mr-2 text-[#ef6603] "
                                                size={15}
                                              />
                                            </td>
                                            <td>
                                              <Trash2
                                                onClick={() =>
                                                  confirmDelete(item.id)
                                                }
                                                className="mr-2 text-[#ef6603]"
                                                size={15}
                                              />
                                            </td>
                                          </div>
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="bg-[#ef6603] text-white rounded-lg shadow-md  flex items-center justify-center py-2 px-3 md:px-4"
                                        onClick={() => handleEdit(item)}
                                      >
                                        <Edit className="mr-2" size={15} /> Edit
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="bg-[#ef6603] text-white rounded-lg shadow-md flex items-center justify-center py-2 px-3 md:px-4 h"
                                        onClick={() => confirmDelete(item.id)}
                                      >
                                        <Trash2
                                          className="mr-2 text-white"
                                          size={15}
                                        />{' '}
                                        Delete
                                      </button>
                                    </td>
                                  </div>
                                  <div className="flex  md:hidden ">
                                    <td className="italic mr-2">
                                      {item.published === 'true' ? (
                                        <p className="text-green-500">
                                          published
                                        </p>
                                      ) : (
                                        <p className="text-red-500">
                                          Not published
                                        </p>
                                      )}
                                    </td>
                                    {user.role === 'admin' && (
                                      <>
                                        <div className="flex md:hidden">
                                          <td
                                            className={`${
                                              user.role !== 'admin' && 'hidden'
                                            }`}
                                          >
                                            {item.published === true ? (
                                              <BookDashed
                                                onClick={() =>
                                                  handleUnpublish(item.id)
                                                }
                                                className="mr-2 text-[#ef6603] "
                                                size={15}
                                              />
                                            ) : (
                                              <BookOpenCheck
                                                onClick={() =>
                                                  handlePublish(item.id)
                                                }
                                                className="mr-2 text-[#ef6603] "
                                                size={15}
                                              />
                                            )}
                                          </td>
                                        </div>
                                      </>
                                    )}
                                    <td>
                                      <Edit
                                        onClick={() => handleEdit(item)}
                                        className="mr-2 text-[#ef6603] "
                                        size={15}
                                      />
                                    </td>
                                    <td>
                                      <Trash2
                                        onClick={() => confirmDelete(item.id)}
                                        className="mr-2 text-[#ef6603]"
                                        size={15}
                                      />
                                    </td>
                                  </div>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5}>No blog available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default Blog
