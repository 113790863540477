import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import { TriangleAlert } from 'lucide-react';

const Unauthorized = () => {
  const [menuStatus, setMenuStatus] = useState(false)

  const onClickMenu = () => {
    setMenuStatus(!menuStatus)
  }

  return (
    <div className="container-scroller">
      <Navbar onClickMenu={onClickMenu} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar menuStatus={menuStatus} />
        <div className="main-panel">
        <div className="h-full bg-[#f2edf3]">
        <div className="w-full h-full flex items-center justify-center text-center bg-black bg-opacity-50 backdrop-blur-lg">
          <div className="bg-white w-[32%] p-8 rounded-md border-2 border-red-500 my-60">
            <div className='w-full flex justify-center text-red-500 mb-2'>
            <TriangleAlert size={50}/>
            </div>
            <h1>Unauthorized</h1>
            <p>You do not have permission to access this page.</p>
          </div>
        </div>
        </div>
        <Footer />
        </div>
      </div>
    </div>
  )
};

export default Unauthorized;
