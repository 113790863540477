import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../DataContex'
import logo from '../assets/logo.jpg'
import authorizePatchRequestWOT from '../api/authorizePatchRequestWOT'
import authorizePostRequestWOT from '../api/authorizePostRequestWOT'

const Login = () => {
  const navigate = useNavigate()
  const { login } = useAppContext()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [forgotPWD, setForgotPWD] = useState(false)
  const [msg, setMsg] = useState('')
  const [isErr, setIsErr] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setIsErr(false)
    setMsg('')

    try {
      if (forgotPWD) {
        const response = await authorizePatchRequestWOT('user/forgotPassword', {
          email,
        })
        setMsg(response.message)
        setIsErr(false)
      } else {
        const response = await authorizePostRequestWOT('user/login', {
          email,
          password,
        })
        const { token, user } = response
        const allowedRoles = ['admin', 'countryAdmin']

        if (allowedRoles.includes(user.role)) {
          login({ user, token })
          setMsg(response.message)
          setIsErr(false)
          navigate('/')
        } else {
          setMsg('Unauthorized role')
          setIsErr(true)
        }
      }
    } catch (error) {
      setMsg(error.message)
      setIsErr(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-3">
                <div className="brand-logo">
                  <img src={logo} alt="logo" />
                </div>
                <h6 className="font-weight-light">Sign in to continue.</h6>
                {loading ? (
                  <div className="loader m-5 text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <form className="pt-3" method="post" onSubmit={handleSubmit}>
                    {msg && (
                      <div
                        className={`alert ${
                          isErr ? 'alert-danger' : 'alert-success'
                        }`}
                        role="alert"
                      >
                        {msg}
                      </div>
                    )}
                    <div className="form-group">
                      <input
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Email"
                        required
                      />
                    </div>
                    {!forgotPWD && (
                      <div className="form-group">
                        <input
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          className="form-control form-control-lg"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          required
                        />
                      </div>
                    )}
                    <div className="mt-3">
                      <button
                        type="submit"
                        className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                      >
                        {forgotPWD ? 'RESET PASSWORD' : 'SIGN IN'}
                      </button>
                    </div>
                    {!forgotPWD && (
                      <div className="my-2">
                        <a
                          href="#"
                          onClick={() => setForgotPWD(true)}
                          className="auth-link text-black"
                        >
                          Forgot password?
                        </a>
                      </div>
                    )}
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
